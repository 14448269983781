// Please keep this in sync with issueKeys.js (for server)

export const IssueAndServiceSharedValues = {
  date: 'Date',
  type: 'Type',
  unit: 'Unit',
  department: 'Department',
  leadExpert: 'Lead/Expert',
  campaign: 'Campaign',
  backgroundResponse: 'Background/Response',
  status: 'Status',
  teamMembers: 'Team Members',
  keyMessaging: 'Key Messaging',
};

export const IssueKeys = {
  date: IssueAndServiceSharedValues.date,
  linkedService: 'Linked Service',
  type: IssueAndServiceSharedValues.type,
  contactMethod: 'Contact',
  topic: 'Topic',
  units: IssueAndServiceSharedValues.unit,
  dept: IssueAndServiceSharedValues.department,
  expert: IssueAndServiceSharedValues.leadExpert,
  campaign: IssueAndServiceSharedValues.campaign,
  outlet: 'Outlet',
  journalist: 'Journalist',
  tone: 'Tone',
  response: IssueAndServiceSharedValues.backgroundResponse,
  status: IssueAndServiceSharedValues.status,
  lead: IssueAndServiceSharedValues.teamMembers,
  keyMessaging: IssueAndServiceSharedValues.keyMessaging,
  file: 'Story Link',
};

// For filter modal
export const ServiceKeys = {
  date: IssueAndServiceSharedValues.date,
  linkedInteraction: 'Media Interactions',
  type: IssueAndServiceSharedValues.type,
  service: 'Service',
  units: IssueAndServiceSharedValues.unit,
  dept: IssueAndServiceSharedValues.department,
  unitLead: IssueAndServiceSharedValues.leadExpert,
  campaign: IssueAndServiceSharedValues.campaign,
  complexity: 'Complexity',
  diversity: 'Diversity',
  actions: IssueAndServiceSharedValues.backgroundResponse,
  status: IssueAndServiceSharedValues.status,
  teamMember: IssueAndServiceSharedValues.teamMembers,
  keyMessaging: IssueAndServiceSharedValues.keyMessaging,
  file: 'Comms Material',
};

export const queryAdderMediaFields = {
  date: 'date',
  contactMethod: 'contactMethod',
  topic: 'topic',
  type: 'type',
  units: 'units',
  expert: 'expert',
  journalist: 'journalist',
  campaigns: 'campaigns',
  lead: 'lead',
  response: 'response',
  keyMessaging: 'keyMessaging',
  status: 'status',
  dept: 'department',
  outlet: 'outlet',
  linkedService: 'linkedService',
};

export const queryAdderServiceKeys = {
  date: 'date',
  service: 'service',
  type: 'type',
  complexity: 'complexity',
  campaigns: 'campaigns',
  units: 'units',
  unitLead: 'unitLead',
  teamMember: 'teamMember',
  diversity: 'diversity',
  status: 'status',
  keyMessaging: 'keyMessaging',
  actions: 'actions',
  linkedInteraction: 'linkedInteraction',
};

// For custom analytics' 'Media Interactions In Progress' chart
export const inProgressKeys = {
  topic: IssueKeys.topic,
  outlet: 'Media Outlet',
  // we map this to IssueKeys.lead instead of IssueAndServiceSharedValues.teamMembers to emphasize the fact that
  // inProgressKeys in the context of issues only
  teamMember: IssueKeys.lead,
  timeStamp: 'Time Stamp',
};

export const TextInputKeys = [
  IssueKeys.topic,
  IssueAndServiceSharedValues.backgroundResponse,
  ServiceKeys.service,
  IssueAndServiceSharedValues.keyMessaging,
];

export const DisplayKeys = {
  ...IssueKeys,
  ...ServiceKeys,
};

export const AutoTrackerKeys = {
  subject: 'Subject',
  sender: 'Sender',
};
