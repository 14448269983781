import * as React from 'react';
import {serviceLogType} from '../../shared/constants/constants';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function UrlDialog(props) {
  const { id, isOpen, setIsOpen, callback, type, colName, handleSubmit } = props;
  const dispatch = useDispatch();

  const [dialogValue, setDialogValue] = React.useState({
    url: '',
    name: '',
    description: '',
  });

  const handleClose = () => {
    setDialogValue({
      url: '',
      name: '',
      description: '',
    });

    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(callback, dialogValue, handleClose, colName, id, dispatch)}>
        <DialogTitle>{type === serviceLogType ? 'Add Link to File' : 'Add a Story Link'}</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              required
              autoFocus
              margin="dense"
              id="url"
              value={dialogValue.url}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  url: event.target.value,
                })
              }
              label={type === serviceLogType ? 'Link to File' : 'URL to Story'}
              type="text"
              variant="standard"
            />
          </div>
          <div>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label={type === serviceLogType ? 'File Name' : 'Story name'}
              type="text"
              variant="standard"
            />
          </div>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              value={dialogValue.description}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  description: event.target.value,
                })
              }
              label="Description"
              type="text"
              variant="standard"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" data-cy="Url-button-submit">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
