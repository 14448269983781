export const tooltipDescriptions = {
  date: 'Date you initially received the media inquiry/interaction',
  contactMethod: 'How the media inquiry/interaction came to you',
  type: 'Specify if the inquiry was driven by the team and if so, what tactic led to media reaching out',
  units: 'Note the unit you are supporting with this request',
  expert: 'Name the spokesperson or expert who will be connected for an interview or will be referenced in the media',
  journalist: 'Who’s asking for our help?',
  campaigns: 'Consider if this media inquiry connects to one of your institution’s campaigns or priorities',
  lead: 'Put your name down if you are leading response of this inquiry',
  status:
    'Toggle this field as you respond – starting with In Progress and Completed when you send the response or arrange the interview. If you work on the inquiry but no expert/spokesperson is available, mark it as Participation Ribbon. If media cancel part way through, that’s where you use the Cancelled button!',
  dept: 'The smaller department or admin unit that is benefitting from your support',
  outlet: 'List the media outlet that the journalist works for',
  linkedService:
    'Consider if the media inquiry connects to a proactive service or issue already inputted into the Service Log. If so, link it so that your media inquiry is nested under that service for quick searches',
  topic:
    'Consider a brief (but not too brief) description that identifies the main topic of interest by media with key words that are easy to search down the road (try to keep it below 100 characters if you can). Try to avoid variations of different topics for a similar inquiry from various media – just use what you already have to maintain constancy',
  response:
    'Outline briefly how you have responded. Remember, the more info you provide now the easier you make life for your colleagues down the line. This is also a good place for leaving notes regarding the media outlet/journalist if there’s anything to consider next time you work with them',
  keyMessaging: 'Input any key messaging, links to statements or other information connected to the interaction',
  complexity:
    'How difficult is this issue to manage? 1 is something easy as pie; 5 is something incredibly complex involving communications plans and many stakeholders',
  diversity: 'Consider if diverse voices were part of the decision-making',
  service:
    'Detail the support/service you have provided your client whether it is media training, consulting on an issue or developing a media release or advisory',
  unitLead: 'Is there an administrative lead or communicator lead you are working with on this service?',
  actions: 'What actions have been taken to respond to this service request',
  teamMember: 'Put your name down if you are leading response of this inquiry',
  mediaInteractions: 'Media Interactions', // TODO Find a description if we ever allow users to modify this
};

export const tooltipDirections = {
  date: 'left',
  contactMethod: 'right',
  type: 'left',
  units: 'left',
  expert: 'left',
  journalist: 'right',
  campaigns: 'right',
  lead: 'right',
  status: 'left',
  dept: 'right',
  outlet: 'left',
  linkedService: 'right',
  topic: 'right',
  response: 'right',
  keyMessaging: 'right',
  complexity: 'left',
  diversity: 'right',
  service: 'right',
  unitLead: 'left',
  actions: 'right',
  teamMember: 'right',
  mediaInteractions: 'right',
};