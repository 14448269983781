import { API } from '../../shared/constants/constants';

export const duplicateSeriveLog = async (id) => {
  await API.post(`/service/${id}/duplicate`);
};

export const duplicateMediaInteraction = async (id) => {
  await API.post(`/issue/${id}/duplicate`);
};

export const getIssueById = async (id) => {
  return await API.get(`issue/${id}`);
};

export const getServiceById = async (id) => {
  return await API.get(`service/${id}`);
};
