import { useState } from 'react';
import { Grid, Box, Modal, Button, Typography, Container } from '@mui/material';
import { useFilters } from '../TrackerFilter/Context/FiltersContext';
import { FileTableWithDragAndDrop } from '../Cells/CellTypes';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const increasedFontSize = '1.1rem';

const EventModal = ({ event, onClose }) => {
  const filters = useFilters();
  const [, setUnsubmittedFiltersSelectedUsingModal] = useState(filters);

  const handleClose = (reason) => {
    if (reason === 'backdropClick') {
      setUnsubmittedFiltersSelectedUsingModal(filters);
    }
    onClose();
  };

  const formatDateString = (date) => {
    // Check if date is valid and not null
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
    }
    return '';
  };

  const isServiceEvent = event?.logtype === 'service';
  const isInteractionEvent = event?.logtype === 'media';
  console.log(event?.logtype);

  return (
    <Modal
      disableEscapeKeyDown
      open={event !== null}
      onClose={() => handleClose('')}
      aria-labelledby="filter-modal-title"
      aria-describedby="tracker-filters"
    >
      <Container
        maxWidth="sm"
        style={{
          outline: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          marginTop: '8px',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '25px',
            m: 'auto',
            p: 3,
            overflow: 'auto',
            maxHeight: 'calc(100vh - 96px)', // Adjust for viewport height minus some margin
            maxWidth: '90%',
            minWidth: '600px',
            top: '50%',
            left: '50%',
          }}
        >
          {isServiceEvent && (
            <Typography variant="h4" component="div" id="filter-modal-title" style={{ marginBottom: '16px' }}>
              Service Log Details
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="h4" component="div" id="filter-modal-title" style={{ marginBottom: '16px' }}>
              Media Interaction Details
            </Typography>
          )}
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Name:</strong> {event?.title}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Date:</strong> {formatDateString(event?.end)}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Lead:</strong> {event?.lead}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Type:</strong> {event?.type}
          </Typography>
          {isServiceEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Team Members:</strong> {event?.team}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Team Members:</strong> {event?.teamMembers}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Campaign:</strong> {event?.campaign}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Unit:</strong> {event?.unit}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Department:</strong> {event?.department}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Background:</strong>
            <RichTextEditor
              defaultValue={''}
              value={event?.background ?? ''}
              readOnly={true}
              quillStyle={{ border: '1px black solid', padding: '6px 6px 10px', minHeight: '5vh' }}
            />
          </Typography>
          {isServiceEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Complexity:</strong> {event?.complexity}
            </Typography>
          )}
          {isServiceEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Diversity:</strong> {event?.diversity}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Contact:</strong> {event?.contact}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Journalist:</strong> {event?.journalist}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Outlet:</strong> {event?.outlet}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Status:</strong> {event?.status}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Key Messaging:</strong>
            <RichTextEditor
              defaultValue={''}
              value={event?.keyMessaging ?? ''}
              readOnly={true}
              quillStyle={{ border: '1px black solid', padding: '6px 6px 10px', minHeight: '5vh' }}
            />
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Comms Material:</strong>{' '}
            <FileTableWithDragAndDrop key={event?.id} files={event?.comms} callback={formatDateString} />
          </Typography>

          <Grid container style={{ marginTop: 16 }}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button onClick={onClose} variant="contained" color="primary">
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
};

export default EventModal;
