import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { cellColors } from '../../shared/constants/commonCellColors';
import stylizeCell from '../../shared/functions/cellStyling';
import CellSelector from '../Cells/CellSelector';
import CellViewer from '../Cells/CellViewer';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    maxWidth: 210,
  },
  ...cellColors,
  input: {
    width: 130,
    height: 40,
  },
  cell: {
    maxWidth: 150,
  },
  label: {
    minWidth: 150,
  },
}));

export default function TrackerTableDataCell({ cells, index, isEditMode, onEdit, dropdownTable, className, id, type }) {
  const classes = useStyles();

  let cellClassName = '';
  let cellStyle = null;
  if (index === IssueAndServiceSharedValues.campaign && !isEditMode) {
    cellClassName = [classes.tableCell, className].join(' ');
    cellStyle = {
      background: cells[index][0]?.backgroundColor,
    };
  } else if (index === IssueAndServiceSharedValues.campaign && isEditMode) {
    cellClassName = classes.tableCell;
    cellStyle = {
      background: cells[index][0]?.backgroundColor,
    };
  } else if (!isEditMode) {
    cellClassName = [classes.tableCell, stylizeCell(cells[index], classes, index), className].join(' ');
  } else {
    cellClassName = [classes.tableCell, stylizeCell(cells[index], classes, index)].join(' ');
  }

  if (!isEditMode) {
    return (
      <TableCell style={cellStyle} className={cellClassName} align="left">
        <CellViewer type={type} cells={cells} index={index} dropdownTable={dropdownTable} id={id} />
      </TableCell>
    );
  } else {
    return (
      <TableCell style={cellStyle} className={cellClassName} align="left">
        <CellSelector
          type={type}
          cells={cells}
          col={index}
          dropdownTable={dropdownTable}
          callBack={onEdit}
          id={id}
          classes={classes}
        />
      </TableCell>
    );
  }
}
