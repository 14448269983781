import { PrivilegeLevels } from './PrivilegeLevels';

/**
 * For addon page routes, must include `addonControl` property.
 *
 * `addonControl` = 'collaboration' | 'engagement' | 'trackerAI' (update if more addons)
 */
export const RouteInformation = {
  serviceLog: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/service-log',
  },
  serviceLogIndividualService: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/service-log/:serviceId',
  },
  mediaInteraction: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/interactions',
  },
  mediaInteractionIndividualIssue: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/interactions/:issueId',
  },
  calendar: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/calendar',
  },
  contacts: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/contacts',
  },
  teamAnalytics: {
    privilegeRequirement: PrivilegeLevels.manager.level,
    path: '/team-analytics',
  },
  customAnalytics: {
    privilegeRequirement: PrivilegeLevels.manager.level,
    path: '/custom-analytics',
  },
  report: {
    privilegeRequirement: PrivilegeLevels.manager.level,
    path: '/report',
  },
  autoTracker: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/tracker-ai',
    addonControl: 'trackerAI',
  },
  admin: {
    privilegeRequirement: PrivilegeLevels.manager.level,
    path: '/admin',
  },
  tenants: {
    privilegeRequirement: PrivilegeLevels.broadsightAdmin.level,
    path: '/tenants',
  },
  profile: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/profile',
  },
  feedback: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSfEA6ilQaZ5VQvp3atKIsuB2An665g6GzxH5zZeNl8OcevYQA/viewform?usp=sf_link',
  },
  notifications: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/notifications',
  },
  termsOfService: {
    privilegeRequirement: PrivilegeLevels.user.level,
    path: '/terms-of-service',
  },
  collaboration: {
    privilegeRequirement: PrivilegeLevels.manager.level,
    path: '/collaboration',
    addonControl: 'collaboration',
  },
};
