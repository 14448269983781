import { TableCell, TableRow } from '@mui/material';
import { AIScrapeResult } from './Interfaces';
import AIResultTableCell from './AIResultTableCell';

interface AIResultTableRowProps {
  aiScrapeResult: AIScrapeResult;
  columns: string[];
  dropdownTable: any;
}

export default function AIResultTableRow({ aiScrapeResult, columns, dropdownTable }: Readonly<AIResultTableRowProps>) {
  return (
    <TableRow>
      <TableCell />
      <TableCell />
      {columns.map((i) => (
        <AIResultTableCell key={i} fieldType={i} aiScrapeResult={aiScrapeResult} dropdownTable={dropdownTable} />
      ))}
    </TableRow>
  );
}
