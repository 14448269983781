import { AIScrapeResult } from './Interfaces';
import { Table } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TrackerTableHeaderRow from '../TrackerTable/TrackerTableHeaderRow';
import AIResultTableRow from './AIResultTableRow';
import { AutoTrackerIssueCols, AutoTrackerCols, mediaInteractionType } from '../../shared/constants/constants';

interface AIScrapeResultTableProps {
  aiScrapeResult: AIScrapeResult;
  eventHandler: any;
  dropdownTable: any;
  tabColor: string;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export function AIScrapeResultTable({
  aiScrapeResult,
  eventHandler,
  dropdownTable,
  tabColor,
}: Readonly<AIScrapeResultTableProps>) {
  const classes = useStyles();

  return (
    <Table className={classes.tableContainer}>
      <TrackerTableHeaderRow
        columns={eventHandler.type === mediaInteractionType ? AutoTrackerIssueCols : AutoTrackerCols}
        type={eventHandler.type}
        tabColor={tabColor}
      />
      <AIResultTableRow
        aiScrapeResult={aiScrapeResult}
        columns={eventHandler.type === mediaInteractionType ? AutoTrackerIssueCols : AutoTrackerCols}
        dropdownTable={dropdownTable}
      />
    </Table>
  );
}
