import React, { useContext, useEffect } from 'react';
import { CollaborationGroupDetailsProps } from './Types/componentProps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Divider, LinearProgress, List, ListItem, Typography } from '@mui/material';
import CollaborationGroupInvitationsList from './CollaborationGroupInvitationsList';
import { getCollaborationGroupDetails } from './Requests/CollaborationGroupRequests';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import AdminPrivilegeProtected from '../../shared/protectedWrappers/AdminPrivilegeProtectedWrapper';
import GroupOwnerProtected from '../../shared/protectedWrappers/CollaborationGroupOwnerProtectedWrapper';
import CollaborationGroupDetailsActions from './CollaborationGroupDetailsActions';
import { makeStyles } from '@mui/styles';
import GroupCollaboratorsActions from './GroupCollaboratorsActions';

const useStyles = makeStyles({
  emptyGroupDetails: {
    marginTop: '1rem',
  },
  loadingProgress: {
    marginTop: '1rem',
  },
  list: {
    paddingTop: '1rem',
  },
  groupNameListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const CollaborationGroupDetails: React.FC<CollaborationGroupDetailsProps> = () => {
  const classes = useStyles();
  const { selectedGroupId } = useContext(SelectedCollaborationGroupIdContext);

  const queryClient = useQueryClient();
  const { isFetching, data: groupDetailsData } = useQuery({
    queryKey: ['collaboration/group-details'],
    queryFn: () => getCollaborationGroupDetails(selectedGroupId),
    enabled: selectedGroupId !== '',
    refetchOnWindowFocus: false,
  });

  const groupOwners = groupDetailsData ? groupDetailsData.owners.map((owner) => owner._id) : [];

  useEffect(() => {
    if (selectedGroupId !== '') {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
    }
  }, [selectedGroupId, queryClient]);

  if (!selectedGroupId) {
    return (
      <Typography data-cy="collaboration-group-details-empty-prompt" variant="h5" className={classes.emptyGroupDetails}>
        Click a group to manage
      </Typography>
    );
  } else if (isFetching || !groupDetailsData) {
    return <LinearProgress className={classes.loadingProgress} />;
  }

  return (
    <List className={classes.list}>
      <ListItem key="group-name" className={classes.groupNameListItem}>
        <Typography variant="h5" data-cy="collaboration-group-details-name">
          {groupDetailsData.name}
        </Typography>
        <AdminPrivilegeProtected>
          <CollaborationGroupDetailsActions groupOwners={groupOwners} />
        </AdminPrivilegeProtected>
      </ListItem>
      <ListItem key="group-owner-title">
        <Typography>
          Group <strong>Leads</strong>:
        </Typography>
      </ListItem>
      {groupDetailsData.owners.map((owner) => (
        <ListItem key={`group-owner-${owner._id}`}>
          <Typography data-cy="collaboration-group-details-owner">
            - {owner.organizationName} ({owner.organizationCode})
          </Typography>
        </ListItem>
      ))}
      <Divider key="group-info-divider" component="li" />
      <ListItem
        key="group-members-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography>
          Group <strong>Collaborators</strong>:
        </Typography>
        <AdminPrivilegeProtected>
          <GroupOwnerProtected groupOwners={groupOwners}>
            <GroupCollaboratorsActions group={groupDetailsData} />
          </GroupOwnerProtected>
        </AdminPrivilegeProtected>
      </ListItem>
      {groupDetailsData.tenants.map((tenant) => (
        <ListItem key={tenant._id}>
          <Typography data-cy="collaboration-group-details-member">
            - {tenant.organizationName} ({tenant.organizationCode})
          </Typography>
        </ListItem>
      ))}
      <Divider key="manage-group-membership-divider" component="li" />
      <AdminPrivilegeProtected>
        <GroupOwnerProtected groupOwners={groupOwners}>
          <CollaborationGroupInvitationsList />
        </GroupOwnerProtected>
      </AdminPrivilegeProtected>
    </List>
  );
};

export default CollaborationGroupDetails;
