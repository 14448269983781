import { API } from '../constants/constants';

export const getServiceById = async (id) => {
  try {
    const response = await API.get(`/services/getServiceById/filter?id=${id}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};
