import React, { useEffect, useState, useContext } from 'react';
import { socketEvents } from '../../shared/constants/constants';
import moment from 'moment';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar-custom-styles.css';
import { useLocation } from 'react-router-dom';
import { getCurrMonth } from '../../shared/functions/dateProcessor';
import CalendarFilter from '../TrackerFilter/CalendarFilter';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import EventModal from './EventModal';
import { useQuery } from '@tanstack/react-query';
import { emitMonthlyIssues, emitMonthlyServices } from './calendarPageRequest';
import { Spinner } from 'react-bootstrap';

const localizer = momentLocalizer(moment);

function CalendarView({ dropdownTable }) {
  const [selectedView, setSelectedView] = useState('service'); // Initialize with 'service' as the default view
  const socket = useContext(SocketContext);
  const { state } = useLocation();
  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventDate, setNewEventDate] = useState(null);
  const [currMonth, setMonth] = useState(getInitialMonth());
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventModalOpen, setEventModalOpen] = useState(false);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [filtersOn, setFiltersOn] = useState(false);

  useEffect(() => {
    setFiltersOn(false);
  }, [selectedView, setSelectedEvent]);

  const handleTabClick = (view) => {
    setFiltersOn(false);

    setSelectedView(view);
  };

  const handleMonthChange = (newDate) => {
    const newMonth = moment(newDate).format('YYYY-MM');
    setMonth(newMonth);
  };

  // TODO use function from constants and cases with constants as well
  const getBackgroundColor = (view) => {
    switch (view) {
      case 'service':
        return '#1F6AC0';
      case 'media':
        return '#003777';
      default:
        return '#002743';
    }
  };

  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const tabButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
    transition: 'background-color 0.3s ease',
  };

  const activeTabButtonStyle = {
    backgroundColor: getBackgroundColor(selectedView),
    color: '#fff',
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setEventModalOpen(true);
  };

  const handleCloseEventModal = () => {
    setSelectedEvent(null);
    setEventModalOpen(false);
  };

  const handleDoubleClickEvent = (event) => {
    setSelectedEvent(event);
    setEventModalOpen(true);
  };

  function getInitialMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    return `${year}-${month.toString().padStart(2, '0')}`;
  }

  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth');
  } else {
    month = getCurrMonth();
  }
  // const itemMutation = useMutation({
  //   mutationFn: handleSelectEvent,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(queryKeys);
  //   },
  // });

  const processData = (data, isServiceView, dropdownTable) => {
    if (data == null) {
      return [];
    }

    return data
      .map((item) => {
        if (!item || !item.cells) {
          console.error('Invalid item format');
          return {};
        }
        const startDate = new Date(item.cells.Date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(item.cells.Date);
        endDate.setHours(0, 0, 0, 0);
        if (isServiceView) {
          let leadExpert;

          try {
            if (item.cells && item.cells['Lead/Expert'] && item.cells['Lead/Expert'].length > 0) {
              leadExpert = item.cells['Lead/Expert'][0];
            } else {
              leadExpert = '';
            }
          } catch (error) {
            leadExpert = '';
          }

          let teamE;

          try {
            if (item.cells && item.cells['Team Member'] && item.cells['Team Member'].length > 0) {
              teamE = item.cells['Team Member'][0];
            } else {
              teamE = '';
            }
          } catch (error) {
            teamE = '';
          }

          const unitE = item.cells['Unit'][0];
          const ttype = item.cells['Type'][0];
          const camp = item.cells['Campaign'][0];
          const depart = item.cells['Department'];
          const teamEname = dropdownTable['lead'].find((lead) => lead._id === teamE);
          const comp = item.cells['Complexity'];
          const div = item.cells['Diversity'];
          const stat = item.cells['Status'];
          const back = item.cells['Background/Response'];
          const key = item.cells['Key Messaging'];
          const com = item.cells['Comms Material'];

          return {
            logtype: 'service',
            start: startDate,
            end: endDate,
            background: back ? back : '',
            title: item.cells.Service ? item.cells.Service : ' ',
            lead: leadExpert ? leadExpert['name'] : ' ',
            unit: unitE ? unitE : ' ',
            campaign: camp ? camp['name'] : ' ',
            department: depart ? depart : ' ',
            type: (ttype['type'] ? ttype['type'] : '') + ': ' + (ttype['name'] ? ttype['name'] : ''),
            team: teamE ? teamEname?.name : ' ',
            complexity: comp ? comp : ' ',
            diversity: div ? div : ' ',
            status: stat ? stat : ' ',
            keyMessaging: key ? key : '',
            colorEvento: 'var(--service-color)',
            comms: com ? com : '',
            id: teamE ? teamE : '',
          };
        } else {
          const leadExpert = item.cells['Lead/Expert'][0];
          const unitE = item.cells['Unit'][0];
          const ttype = item.cells['Type'][0];
          const camp = item.cells['Campaign'][0];
          const depart = item.cells['Department'];
          const teamE = item.cells['Team Members'][0];
          const back = item.cells['Background/Response'];
          const cont = item.cells['Contact'];
          const stat = item.cells['Status'];
          const journ = item.cells['Journalist'];
          const journName = dropdownTable['journalist']?.find((journalist) => journalist['_id'] === journ);
          const key = item.cells['Key Messaging'];
          const outlett = item.cells['Outlet'];
          const outlettName = dropdownTable['outlet']?.find((org) => org['_id'] === outlett);
          const com = item.cells['Comms Material'];
          return {
            logtype: 'media',
            start: startDate,
            end: endDate,
            title: item.cells.Topic,
            colorEvento: 'var(--media-color)',
            lead: leadExpert ? leadExpert['name'] : ' ',
            unit: unitE ? unitE : ' ',
            type: (ttype['type'] ? ttype['type'] : '') + ': ' + (ttype['name'] ? ttype['name'] : ''),
            campaign: camp ? camp['name'] : ' ',
            department: depart ? depart : ' ',
            status: stat,
            background: back ? back : '',
            teamMembers: teamE ? teamE['name'] : '',
            contact: cont ? cont : '',
            journalist: journName ? journName.name : '',
            keyMessaging: key ? key : '',
            outlet: outlett ? outlettName.name : '',
            comms: com ? com : '',
            id: journ ? journ : '',
          };
        }
      })
      .filter((item) => item !== null);
  };

  const serviceQuery = useQuery({
    queryKey: ['services', currMonth],
    queryFn: () => emitMonthlyServices(socket, currMonth),
    enabled: selectedView === 'service' || selectedView === 'both',
    onError: (err) => {
      console.log(err);
    },
    refetchInterval: selectedView === 'service' || selectedView === 'both' ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  const mediaQuery = useQuery({
    queryKey: ['media', currMonth],
    queryFn: () => emitMonthlyIssues(socket, currMonth),
    enabled: selectedView === 'media' || selectedView === 'both',
    onError: (err) => {
      console.log(err);
    },
    refetchInterval: selectedView === 'media' || selectedView === 'both' ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    setFormattedEvents([]);
    if (dropdownTable) {
      if (selectedView === 'media') {
        if (filtersOn) {
          const formattedMediaEvents = processData(filteredIssues, false, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedMediaEvents]);
        } else if (mediaQuery.isSuccess && mediaQuery.data) {
          const formattedMediaEvents = processData(mediaQuery.data, false, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedMediaEvents]);
        }
      }

      if (selectedView === 'service') {
        if (filtersOn) {
          const formattedServiceEvents = processData(filteredIssues, true, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedServiceEvents]);
        } else if (serviceQuery.isSuccess && serviceQuery.data) {
          const formattedServiceEvents = processData(serviceQuery.data, true, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedServiceEvents]);
        }
      }
      if (selectedView === 'both') {
        if (serviceQuery.isSuccess && serviceQuery.data) {
          const formattedServiceEvents = processData(serviceQuery.data, true, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedServiceEvents]);
        }
        if (mediaQuery.isSuccess && mediaQuery.data) {
          const formattedMediaEvents = processData(mediaQuery.data, false, dropdownTable);
          setFormattedEvents((formattedEvents) => [...formattedEvents, ...formattedMediaEvents]);
        }
      }
    }
  }, [
    selectedView,
    currMonth,
    month,
    serviceQuery.data,
    mediaQuery.data,
    filteredIssues,
    filtersOn,
    dropdownTable,
    mediaQuery.isSuccess,
    serviceQuery.isSuccess,
  ]);

  useEffect(() => {
    sessionStorage.setItem('currMonth', currMonth);

    // if (!state) {
    if (selectedView === 'media') {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
        month: currMonth,
        timezoneOffset: new Date(currMonth).getTimezoneOffset(),
      });

      if (newEventTitle && newEventDate) {
        socket.emit(socketEvents.EVENT_CREATE_EVENT, {
          title: newEventTitle,
          date: newEventDate,
        });

        setNewEventTitle('');
        setNewEventDate('');
      }
    }
    if (selectedView === 'service') {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_SERVICES, {
        month: currMonth,
        timezoneOffset: new Date(currMonth).getTimezoneOffset(),
      });

      if (newEventTitle && newEventDate) {
        socket.emit(socketEvents.EVENT_CREATE_EVENT, {
          title: newEventTitle,
          date: newEventDate,
        });

        setNewEventTitle('');
        setNewEventDate('');
      }
    }
    // }
  }, [currMonth, socket, state, newEventTitle, newEventDate, selectedView, setMonth, month]);

  return (
    <div>
      {dropdownTable ? (
        <div>
          <div>
            {selectedView !== 'both' && (
              <CalendarFilter
                setDataCallback={setFilteredIssues}
                currMonth={currMonth}
                dropdownTable={dropdownTable}
                onSelectEvent={handleSelectEvent}
                eventHandler={selectedView === 'media' ? mediaInteractionEventHandler : serviceLogEventHandler}
                inMediaInteraction={selectedView === 'media'}
                typeView={selectedView}
                setFiltersOn={setFiltersOn}
                filtersOn={filtersOn}
              />
            )}
            <div style={buttonStyle} className="tabs">
              <button
                style={selectedView === 'service' ? { ...tabButtonStyle, ...activeTabButtonStyle } : tabButtonStyle}
                onClick={() => handleTabClick('service')}
              >
                Service Logs
              </button>
              <button
                style={selectedView === 'media' ? { ...tabButtonStyle, ...activeTabButtonStyle } : tabButtonStyle}
                onClick={() => handleTabClick('media')}
              >
                Media Interactions
              </button>
              <button
                style={selectedView === 'both' ? { ...tabButtonStyle, ...activeTabButtonStyle } : tabButtonStyle}
                onClick={() => handleTabClick('both')}
              >
                All
              </button>
            </div>
          </div>
          <div>
            <div className="myCustomHeight">
              <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: '40vw',
                  width: '100vw',
                  padding: '8px',
                }}
                events={formattedEvents}
                views={['month', 'week', 'day']}
                defaultView={'month'}
                eventPropGetter={(myEventsList) => {
                  const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : 'blue';
                  return { style: { backgroundColor } };
                }}
                onNavigate={(newDate) => handleMonthChange(newDate)}
                onDoubleClickEvent={handleDoubleClickEvent}
              />

              {isEventModalOpen && <EventModal event={selectedEvent} onClose={handleCloseEventModal} />}
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
}

export default CalendarView;
