import React from 'react';
import { EmailQueueEntry } from './Interfaces';
import { AutoTrackerKeys, IssueAndServiceSharedValues, IssueKeys } from '../../shared/constants/IssueAndServiceKeys';
import { TableCell } from '@mui/material';

interface EmailEntryCellProps {
  row: EmailQueueEntry;
  column: string;
  dropdownTable: any;
}

const EmailEntryCell: React.FC<EmailEntryCellProps> = ({ row, column, dropdownTable }) => {
  let value;
  switch (column) {
    case IssueAndServiceSharedValues.date:
      value = new Date(row.date).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
      break;
    case AutoTrackerKeys.subject:
      value = row.subject;
      break;
    case AutoTrackerKeys.sender:
      value = dropdownTable.lead.find((lead: any) => lead._id === row.sender)?.name;
      break;
    case IssueKeys.outlet:
      value = row.aiScrapeResult?.Outlet?.name ?? row.aiScrapeResult?.Outlet;
      break;
    case IssueKeys.journalist:
      value = row.aiScrapeResult?.Journalist?.name ?? row.aiScrapeResult?.Journalist;
  }

  return <TableCell align="left">{value}</TableCell>;
};

export default EmailEntryCell;
