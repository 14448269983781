import { AIScrapeResult } from './Interfaces';
import { IssueAndServiceSharedValues, IssueKeys } from '../../shared/constants/IssueAndServiceKeys';
import { addContact } from './emailScrapingRequests';
import { API } from '../../shared/constants/constants';
import { useQueryClient } from '@tanstack/react-query';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';

interface MissingContactButtonProps {
  fieldType: string;
  dropdownTable: any;
  aiScrapeResult: AIScrapeResult;
  textValue: string;
}

export default function MissingContactButton({
  fieldType,
  dropdownTable,
  aiScrapeResult,
  textValue,
}: Readonly<MissingContactButtonProps>) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const journalistCallback = async () => {
    try {
      let updatedDropdownTable = dropdownTable;
      if (aiScrapeResult.Outlet) {
        const initialOutlet = dropdownTable.outlet.find(
          (val: any) => val.name === (aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet)
        )?._id;
        if (!initialOutlet) {
          await addContact(IssueKeys.outlet, aiScrapeResult, updatedDropdownTable);
          updatedDropdownTable = (await API.get('dropdown-table'))?.data;
        }
      }
      await addContact(IssueKeys.journalist, aiScrapeResult, updatedDropdownTable);
      queryClient.invalidateQueries({ queryKey: ['dropdown-table'] });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: `${fieldType}: ${textValue} created!`,
        })
      );
    } catch (e: any) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: `There was an error creating the ${fieldType}`,
        })
      );
    }
  };

  const enumCallback = async () => {
    try {
      await addContact(fieldType, aiScrapeResult, dropdownTable);
      queryClient.invalidateQueries({ queryKey: ['dropdown-table'] });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: `${fieldType}: ${textValue} created!`,
        })
      );
    } catch (e: any) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: `There was an error creating the ${fieldType}`,
        })
      );
    }
  };

  switch (fieldType) {
    case IssueKeys.journalist:
      return (
        <Button onClick={() => journalistCallback()} startIcon={<Add />} variant={'outlined'}>
          Add
        </Button>
      );
    case IssueKeys.outlet:
    case IssueAndServiceSharedValues.leadExpert:
    case IssueAndServiceSharedValues.unit:
    case IssueAndServiceSharedValues.department:
      return (
        <Button onClick={() => enumCallback()} startIcon={<Add />} variant={'outlined'}>
          Add
        </Button>
      );
    default:
      return <div>Missing field, please add</div>;
  }
}
