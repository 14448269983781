import { useEffect, useState, useContext } from 'react';
import { Box, Modal, TextField, Button, Typography, Container, InputAdornment, Tooltip, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import TitleIcon from '@mui/icons-material/Title';
import FaceIcon from '@mui/icons-material/Face';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StyleIcon from '@mui/icons-material/Style';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SchoolIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';
import { Autocomplete } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DnsIcon from '@mui/icons-material/Dns';
import FortIcon from '@mui/icons-material/Fort';
import { useLocation, useNavigationType } from 'react-router-dom';
import { DisplayKeys } from '../../shared/constants/IssueAndServiceKeys';
import {
  socketEvents,
  API,
  serviceLogType,
  managerAnalyticsType,
  getTabColorHeaderMain,
} from '../../shared/constants/constants';
import { Stack } from '@mui/material';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { useFilters, useFiltersDispatch } from './Context/FiltersContext';
import { useFilteredIssuesDispatch } from './Context/FilteredIssuesContext';
import { needRefetchFilteredDataAtom } from '../../shared/atoms/filterAtoms';
import { useAtom } from 'jotai';

const useStyles = makeStyles(({ palette, tabColor }) => ({
  button: {
    margin: '0 20px',
  },
  content: {
    backgroundColor: 'white',
    padding: '20px 30px',
    maxWidth: '700px',
    minHeight: '600px',
    maxHeight: '90%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '25px',
    overflow: 'scroll',
  },
  filterContainer: {
    margin: '20px 0',
  },
  title: {
    fontSize: '26px',
    fontWeight: 700,
    textAlign: 'center',
  },
  filterTitle: {
    margin: '8px 0px',
    fontSize: '20px',
    fontWeight: 600,
  },
  filterInput: {
    width: '100%',
  },
  helpText: {
    fontSize: '16px',
    marginLeft: 0,
  },
  filterButton: {
    width: '110px',
    height: '40px',
    fontSize: '16px',
    margin: '0 10px',
    fontWeight: 550,
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
  },
  buttonContainer: {
    display: 'block',
    margin: '15px auto',
    textAlign: 'center',
  },
  filterIcon: {
    margin: '0 10px',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  filterIconNested: {
    padding: '20px 0',
    margin: '2px',
    maxWidth: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupLabel: {
    background: ({ tabColor }) => tabColor,
    color: palette.primary.contrastText,
    fontWeight: 700,
  },
  dateRangeContainer: {
    display: 'inline-flex',
    width: '100%',
    '& > .react-datepicker-wrapper': {
      width: 'auto',
    },
    flexWrap: 'wrap',
  },
  dateRange: {
    padding: 8,
    margin: 'auto',
    '&:focus-visible': {
      outlineColor: palette.primary.dark,
    },
  },
  dateRangeText: {
    width: '100px',
  },
}));

const FiltersEnum = {
  outlet: 'outlet',
  journalist: 'journalist',
  topic: 'topic',
  expert: 'expert',
  units: 'units',
  startDate: 'Start Date',
  endDate: 'End Date',
  linkedService: 'linkedService',
  teamMember: 'Lead/Expert',
  service: 'service',
  complexity: 'complexity',
  type: 'type',
  campaigns: 'Campaigns',
  teamMembers: 'Team Members',
  status: 'status',
  linkedInteraction: 'linkedInteraction',
};

const dateInputFilters = [FiltersEnum.startDate, FiltersEnum.endDate];
const textInputFiltersMap = {
  MediaInteraction: [FiltersEnum.topic],
  ServiceLog: [FiltersEnum.service],
  ManagerAnalytics: [],
};
const selectInputFiltersMap = {
  MediaInteraction: [
    FiltersEnum.outlet,
    FiltersEnum.journalist,
    FiltersEnum.expert,
    FiltersEnum.units,
    FiltersEnum.linkedService,
    FiltersEnum.type,
    FiltersEnum.status,
    FiltersEnum.campaigns,
    FiltersEnum.teamMembers,
  ],
  ServiceLog: [
    FiltersEnum.units,
    FiltersEnum.teamMember,
    FiltersEnum.complexity,
    FiltersEnum.campaigns,
    FiltersEnum.type,
    FiltersEnum.status,
    FiltersEnum.teamMembers,
  ],
  ManagerAnalytics: [FiltersEnum.teamMembers],
};

const textFiltersHelpText = {
  [FiltersEnum.journalist]: "Type a journalist's name and see how we have responded to them in the past",
  [FiltersEnum.units]: 'Search by Unit/Faculty/Area',
};

const filterIconMap = {
  MediaInteraction: {
    [FiltersEnum.outlet]: <DescriptionIcon />,
    [FiltersEnum.expert]: <FaceIcon />,
    [FiltersEnum.topic]: <TitleIcon />,
    [FiltersEnum.journalist]: <AssignmentIndIcon />,
    [FiltersEnum.units]: <SchoolIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
    [FiltersEnum.linkedService]: <DnsIcon />,
    [FiltersEnum.status]: <BuildIcon />,
    [FiltersEnum.campaigns]: <FortIcon />,
    [FiltersEnum.type]: <StyleIcon />,
    [FiltersEnum.teamMembers]: <FaceIcon />,
  },
  ServiceLog: {
    [FiltersEnum.service]: <DescriptionIcon />,
    [FiltersEnum.teamMember]: <FaceIcon />,
    [FiltersEnum.units]: <SchoolIcon />,
    [FiltersEnum.complexity]: <FormatListNumberedIcon />,
    [FiltersEnum.type]: <StyleIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
    [FiltersEnum.status]: <BuildIcon />,
    [FiltersEnum.linkedInteraction]: <DnsIcon />,
    [FiltersEnum.campaigns]: <FortIcon />,
    [FiltersEnum.teamMembers]: <FaceIcon />,
  },
  ManagerAnalytics: {
    [FiltersEnum.teamMembers]: <FaceIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
  },
};

export default function FilterModal(props) {
  const {
    open,
    setModalOpen,
    dropdownTable,
    setDataCallback,
    currMonth,
    eventHandler,
    filterTags,
    setFilterTags,
    setFiltersOn,
    filtersOn,
  } = props;
  const { state, pathname } = useLocation();
  let initialState = state;

  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });
  if (state?.queryString) {
    initialState = {};
  }

  const filters = useFilters();
  const dispatch = useFiltersDispatch();
  const navigationType = useNavigationType();
  const dispatchFilter = useFilteredIssuesDispatch();
  useEffect(() => {
    if (initialState) dispatchFilter({ type: 'setFilteredIssues', payload: initialState });
    //keep dep array empty to avoid render loops
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialState) dispatch({ type: 'setFilters', payload: initialState });
    //keep dep array empty to avoid render loops
    //eslint-disable-next-line
  }, []);

  const [unsubmittedFiltersSelectedUsingModal, setUnsubmittedFiltersSelectedUsingModal] = useState(filters);
  window.history.replaceState(null, pathname);
  const socket = useContext(SocketContext);
  const filterIcon = filterIconMap[eventHandler.type];
  const textInputFilters = textInputFiltersMap[eventHandler.type];
  const selectInputFilters = selectInputFiltersMap[eventHandler.type];
  const [needRefetchFilteredData, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);

  useEffect(() => {
    if (!filtersOn) {
      setDataCallback([]);
      setFilterTags({});
      setUnsubmittedFiltersSelectedUsingModal({});
      dispatch({
        type: 'setFilters',
        payload: {},
      });
    }
    //ignoring setDataCallback, setFilterTags in dep array
    //eslint-disable-next-line
  }, [filtersOn, setFiltersOn, dispatch]);

  useEffect(() => {
    setUnsubmittedFiltersSelectedUsingModal({ ...filterTags });
  }, [setFilterTags, filterTags]);

  useEffect(() => {
    async function emitData() {
      let overwriteFilter = false;
      // This is to deal with blank screen bug that happens when we are at Media Interactions/Service Log with filters applied and
      // we hit the back button to return to Service Log/Media Interactions. The reason being that filter context does not
      // get reset by the back button.
      if (navigationType === 'POP') {
        // Filter context is unfortunately not updated instantly. So we need to do onSearch using empty object while waiting for
        // filter context to reset.
        overwriteFilter = true;
        dispatch({ type: 'setFilters', payload: {} });
      }

      if (filters) {
        socket.emit(socketEvents.EVENT_REQ_DROPDOWN_TABLE, 'plz');
        await onSearch(overwriteFilter ? {} : filters);
      }
    }
    emitData();
    //ignoring filters, navigationType, onSearch in dep array
    //eslint-disable-next-line
  }, [socket, dispatch]);

  useEffect(() => {
    if (needRefetchFilteredData) {
      onSearch(filters).then(() => {
        setNeedRefetchFilteredData(false);
      });
    }
    //ignoring filters, onSearch, setNeedRefetchFilteredData
    //eslint-disable-next-line
  }, [needRefetchFilteredData]);

  const onSearch = async (currFilters) => {
    setModalOpen(false);
    setDataCallback([]);
    const filterTags = { ...currFilters };
    dateInputFilters.forEach((df) => {
      if (filterTags[df]) {
        const offsetMs = filterTags[df].getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(filterTags[df].getTime() - offsetMs);
        filterTags[df] = `${df} ${dateLocal.toISOString().slice(0, 10)}`;
      }
    });
    setFilterTags(filterTags);

    const existingFilters = Object.keys(currFilters).filter(
      (f) => currFilters[f] && (!Array.isArray(currFilters[f]) || currFilters[f].length > 0)
    );

    if (existingFilters.length === 0) {
      setFiltersOn(false);
      return;
    } else {
      setFiltersOn(true);
    }

    const groupFilterName = (f) => {
      return `${f.toLowerCase()}=${JSON.stringify(currFilters[f].map((value) => value.name))}`;
    };

    const groupFilterId = (f) => {
      return `${f.toLowerCase()}=${JSON.stringify(currFilters[f].map((value) => value._id))}`;
    };

    const query = existingFilters
      .map((f) => {
        if (textInputFilters.includes(f)) return `${f.toLowerCase()}=${encodeURIComponent(currFilters[f])}`;

        if (dateInputFilters.includes(f)) {
          if (f === FiltersEnum.endDate) {
            const date = new Date(currFilters[f]);
            date.setDate(date.getDate() + 1);
            return `${f.toLowerCase().replaceAll(' date', 'Date')}=${date.toISOString()}`;
          }
          return `${f.toLowerCase().replaceAll(' date', 'Date')}=${currFilters[f].toISOString()}`;
        }

        if (f === FiltersEnum.units) return groupFilterName(f);

        if (f === FiltersEnum.outlet) return groupFilterId(f);

        if (f === FiltersEnum.status) return groupFilterName(f);

        if (f === FiltersEnum.campaigns) return groupFilterId(f);

        if (f === FiltersEnum.linkedInteraction) return `${f.toLowerCase()}=${currFilters[f]._id}`;

        return groupFilterId(f);
      })
      .join('&');
    if (eventHandler.type === managerAnalyticsType) {
      eventHandler.getData(query, currMonth, setDataCallback);
    } else {
      const url = `/${eventHandler.type === serviceLogType ? 'services' : 'issues'}/filter?${query}`;

      API.get(url)
        .then((res) => {
          const filteredIssues = res.data.data || [];
          setDataCallback(filteredIssues);
        })
        .catch((err) => {
          alert(err);
          setDataCallback([]);
        });
    }
  };

  const renderFilterTags = () => {
    const existingTags = Object.keys(filterTags).filter((ft) => filterTags[ft]);
    if (existingTags.length === 0) {
      return;
    }
    return (
      <Box className={classes.buttonContainer}>
        {existingTags
          .filter((tag) => selectInputFilters.find((element) => tag === element))
          .map((tagGroup) => {
            return (
              <Chip
                icon={renderFilterIcon(tagGroup)}
                label={
                  <Stack direction={'row'}>
                    {filterTags[tagGroup].map((tag) => (
                      <Chip
                        label={tag.name || tag._id || tag}
                        key={tag._id}
                        variant="outlined"
                        onDelete={() => {
                          let filtersTagsInTagGroupExcludingToBeDeletedTag = filters[tagGroup].filter(
                            (element) => element.name !== tag.name
                          );

                          if (filtersTagsInTagGroupExcludingToBeDeletedTag.length === 0) {
                            filtersTagsInTagGroupExcludingToBeDeletedTag = undefined;
                          }

                          onSearch({
                            ...filters,
                            [tagGroup]: filtersTagsInTagGroupExcludingToBeDeletedTag,
                          });
                          dispatch({
                            type: 'setFilters',
                            payload: {
                              ...filters,
                              [tagGroup]: filtersTagsInTagGroupExcludingToBeDeletedTag,
                            },
                          });
                        }}
                        className={classes.filterIcon}
                      />
                    ))}
                  </Stack>
                }
                key={tagGroup}
                variant="outlined"
                onDelete={() => {
                  onSearch({ ...filters, [tagGroup]: undefined });
                  dispatch({
                    type: 'setFilters',
                    payload: {
                      ...filters,
                      [tagGroup]: undefined,
                    },
                  });
                }}
                className={classes.filterIconNested}
                onClick={() => setModalOpen(true)}
              />
            );
          })}
        {existingTags
          .filter((tag) => !selectInputFilters.find((element) => tag === element))
          .map((tag) => {
            return (
              <Chip
                icon={renderFilterIcon(tag)}
                label={
                  typeof filterTags[tag] === 'object'
                    ? filterTags[tag].name || filterTags[tag]._id || JSON.stringify(filterTags[tag])
                    : filterTags[tag]
                }
                variant="outlined"
                key={tag}
                onDelete={() => {
                  onSearch({ ...filters, [tag]: undefined });
                  dispatch({
                    type: 'setFilters',
                    payload: {
                      ...filters,
                      [tag]: undefined,
                    },
                  });
                }}
                className={classes.filterIcon}
                onClick={() => setModalOpen(true)}
              />
            );
          })}
        <Button
          onClick={() => {
            setDataCallback([]);
            setFiltersOn(false);
            setFilterTags({});
            dispatch({
              type: 'setFilters',
              payload: {},
            });
          }}
          variant="outlined"
          color="secondary"
        >
          Clear All
        </Button>
      </Box>
    );
  };

  const renderFilterIcon = (filter) => {
    const icon = filterIcon[filter];
    if (!icon) return null;

    return (
      <Tooltip title={<Typography fontSize={22}>{filter}</Typography>} arrow={true}>
        {icon}
      </Tooltip>
    );
  };

  const renderSelectInputFilter = () => {
    const filterKeyMap = {
      [FiltersEnum.teamMember]: 'expert',
      [FiltersEnum.teamMembers]: 'lead',
      [FiltersEnum.linkedService]: 'service',
      [FiltersEnum.status]: 'status',
      [FiltersEnum.campaigns]: 'campaigns',
    };
    return selectInputFilters.map((filter) => {
      const key = filterKeyMap[filter] || filter;
      let options = [];
      let groupBy = (option) => option.firstLetter;
      if (key === FiltersEnum.complexity) {
        options = [...Array(5).keys()].map((i) => ({ _id: i + 1, name: `${i + 1}` }));
        groupBy = undefined;
      } else {
        options =
          (dropdownTable[key] || []).map((item) => ({
            ...item,
            firstLetter: item.name?.charAt(0),
          })) || [];
        if (key === FiltersEnum.type) {
          options = options.sort(
            (a, b) => -b?.type?.localeCompare(a?.type) || -b?.firstLetter?.localeCompare(a?.firstLetter)
          );
          groupBy = (option) => option.type;
        } else {
          options = options.sort((a, b) => -b?.firstLetter?.localeCompare(a?.firstLetter));
        }
      }
      return (
        <Box key={filter}>
          <Typography className={classes.filterTitle}>{DisplayKeys[filter] || filter}</Typography>
          <Autocomplete
            id={`select-filter-${filter}`}
            options={options}
            groupBy={groupBy}
            multiple={true}
            getOptionLabel={(option) => option.name || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option._id}>
                {option.name}
              </Box>
            )}
            value={unsubmittedFiltersSelectedUsingModal[filter] || []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />;
              })
            }
            onChange={(_, v) => {
              setUnsubmittedFiltersSelectedUsingModal({ ...unsubmittedFiltersSelectedUsingModal, [filter]: v });
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            variant="outlined"
            classes={{ groupLabel: classes.groupLabel }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                helperText={textFiltersHelpText[filter]}
                size="small"
                id={`${filter}-filter-input`}
                InputProps={
                  filterIcon[filter] && {
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start" fontSize="20px">
                          {renderFilterIcon(filter)}
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }
                }
                className={classes.filterInput}
                FormHelperTextProps={{ className: classes.helpText }}
              />
            )}
          />
        </Box>
      );
    });
  };

  const renderModalButtons = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.filterButton}
          variant="outlined"
          color="primary"
          onClick={() => {
            setUnsubmittedFiltersSelectedUsingModal(filters);
            setModalOpen(false);
          }}
          data-cy="calendar-filter-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          className={classes.filterButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch({
              type: 'setFilters',
              payload: {
                ...unsubmittedFiltersSelectedUsingModal,
              },
            });
            onSearch(unsubmittedFiltersSelectedUsingModal);
          }}
          data-cy="calendar-filter-modal-search-button"
        >
          Search
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Modal
        disableEscapeKeyDown
        open={open}
        onClose={(_, reason) => {
          // Closing filter modal with backdrop click will have same behavior as clicking cancel button
          if (reason === 'backdropClick') {
            setUnsubmittedFiltersSelectedUsingModal(filters);
          }
          setModalOpen(false);
        }}
        aria-labelledby="filter-modal-title"
        aria-describedby="tracker-filters"
      >
        <Container className={classes.content}>
          <Typography className={classes.title} color="primary">
            Tracker Filters
          </Typography>
          <Box className={classes.filterContainer}>
            {/* {renderTextInputFilter()} */}
            {renderSelectInputFilter()}
            {renderModalButtons()}
          </Box>
        </Container>
      </Modal>
      {renderFilterTags()}
    </>
  );
}
