import { Box, Collapse, Card, CardContent, TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CellSelector from '../Cells/CellSelector';
import CellViewer from '../Cells/CellViewer';
import QueryAdder from '../QueryAdder/QueryAdder';
import NewIssuePopup from '../QueryAdder/NewIssuePopup';
import { useState, createContext } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddBox';
import ToolTip from '@mui/material/Tooltip';
import { useRef } from 'react';
import { API, getTabColorHeaderMain, serviceLogType } from '../../shared/constants/constants';
import { useLocation } from 'react-router-dom';
import { processCellsForAutoComplete } from '../../shared/functions/autoCompleteProcessing';
import AddLinkIcon from '@mui/icons-material/AddLink';
import UrlDialog from '../ServiceLog/UrlDialog';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles(({ palette }) => ({
  hiddenCellCard: {
    flex: 1,
    alignSelf: 'flex-start',
    margin: '10px',
    textAlign: 'center',
    border: `1px solid ${palette.secondary.dark}`,
  },
  cardContent: {
    padding: '0 !important',
    borderRadius: '10%',
  },
  cardName: {
    background: ({ tabColor }) => tabColor,
    padding: 8,
    color: palette.primary.contrastText,
    fontWeight: 800,
  },
  cardData: {
    padding: 5,
    wordWrap: 'break-word',
  },
  wideColContainer: {
    flex: 1,
    display: 'flex',
    width: '50%',
    flexBasis: '15vw',
    flexDirection: 'row',
  },
  fixedWideColContainer: {
    flex: 1,
    display: 'flex',
    width: '75%',
    flexDirection: 'row',
  },
  colContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '50%',
    flexDirection: 'row',
  },
  collapseWrapperInner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
  },
  dataCell: {
    padding: '6px',
    textAlign: 'center',
    border: `1px solid ${palette.secondary.dark}`,
  },
  input: {
    width: '100%',
    maxHeight: '40vh',
    overflowY: 'scroll',
    overflowWrap: 'break-word',
    textAlign: 'left',
    '&> div': {
      padding: 8,
    },
  },
  textArea: {
    height: '13.2vh',
    width: '100%',
    backgroundColor: 'white',
  },
  stackBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addLinkedButton: {
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
    '&.MuiButton-contained:hover': {
      background: ({ tabColor }) => tabColor,
    },
    marginTop: '5vh',
    width: '60%',
  },
  boxButton: {
    color: palette.primary.contrastText,
  },
}));

const wideColsMap = {
  MediaInteraction: [IssueKeys.keyMessaging, IssueKeys.response],
  ServiceLog: [ServiceKeys.keyMessaging, ServiceKeys.actions],
};

const fixedWideColsMap = {
  MediaInteraction: [IssueKeys.file],
  ServiceLog: [ServiceKeys.file],
};

const otherColsMap = {
  MediaInteraction: [IssueKeys.dept, IssueKeys.contactMethod, IssueKeys.linkedService],
  ServiceLog: [ServiceKeys.diversity, ServiceKeys.linkedInteraction],
};

const FileUploadButton = ({ file, onChange }) => {
  return (
    <>
      <input type="file" multiple id="file" ref={file} onChange={onChange} style={{ display: 'none' }} />
      <ToolTip title="add file(s)">
        <label htmlFor="file" data-cy="file-upload-button">
          <IconButton onClick={() => file.current.click()} size="large">
            <AddIcon style={{ color: 'white' }} />
          </IconButton>
        </label>
      </ToolTip>
    </>
  );
};

const UrlUploadButton = ({ setFormOpen }) => {
  return (
    <>
      <ToolTip title="add link to web-hosted file">
        <IconButton onClick={() => setFormOpen(true)} size="large" data-cy="url-upload-button">
          <AddLinkIcon style={{ color: 'white' }} />
        </IconButton>
      </ToolTip>
    </>
  );
};

export const FileProgressContext = createContext();

const TrackerCollapsedRow = ({
  cells,
  open,
  colSpan,
  dropdownTable,
  isEditMode,
  onEdit,
  type,
  linkedEventHandler,
  id,
}) => {
  const { pathname } = useLocation();
  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });
  const dispatch = useDispatch();

  const file = useRef(null);
  const [fileProgress, setFileProgress] = useState();

  const [isUrlFormOpen, setIsUrlFormOpen] = useState(false);

  const [isDragActive, setDragActive] = useState(false);

  const onChange = (colName) => (e) => {
    if (e.target.files.length) {
      const headers = { 'content-type': 'multipart/form-data' };
      let files = new FormData();

      Object.values(e.target.files).forEach((file) => {
        files.append('file', file);
      });

      setFileProgress(0);

      API.post(`/service/${id}/upload`, files, {
        headers: headers,
        onUploadProgress: (data) => {
          setFileProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            alert(response.statusText);
          } else {
            dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
          }

          const filesData = response.data.files || null;
          const cellData = cells[colName].concat(filesData);

          onEdit(cellData, colName);
          return response;
        })
        .catch((err) => {
          alert(`post failed - ${err}`);
        });
    }
  };

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (colName) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files && e.dataTransfer.files[0] && isDragActive) {
      const headers = { 'content-type': 'multipart/form-data' };
      let files = new FormData();

      Object.values(e.dataTransfer.files).forEach((file) => {
        files.append('file', file);
      });

      setFileProgress(0);

      API.post(`/service/${id}/upload`, files, {
        headers: headers,
        onUploadProgress: (data) => {
          setFileProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            alert(response.statusText);
          } else {
            dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
          }

          const filesData = response.data.files || null;
          const cellData = cells[colName].concat(filesData);

          onEdit(cellData, colName);
          return response;
        })
        .catch((err) => {
          alert(`post failed - ${err}`);
        });
    }
    setDragActive(false);
  };

  const handleSubmit = (callback, dialogValue, handleClose, colName, id, dispatch) => (event) => {
    event.preventDefault();

    API.post(`/${type === serviceLogType ? 'service' : 'issues'}/${id}/url`, dialogValue)
      .then((response) => {
        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(openSnackbar({ severity: 'success', message: `${colName} entry added!` }));
        }
        return response;
      })
      .then((data) => {
        callback(data, colName);
      })
      .catch((e) => {
        console.log('post failed - ', e);
      });

    handleClose();
  };

  const handleCommsChange = (response, colName) => {
    const filesData = response.data.updatedService?.file || response.data.updatedIssue?.file || null;

    onEdit(filesData, colName);
  };

  const [openForm, setOpenForm] = useState(false);

  const renderHiddenCell = (colName) => {
    return (
      <Card key={colName} className={classes.hiddenCellCard}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardName}>
            {colName}{' '}
            {colName === ServiceKeys.file || colName === IssueKeys.file ? (
              <>
                <UrlDialog
                  colName={colName}
                  type={type}
                  id={id}
                  isOpen={isUrlFormOpen}
                  setIsOpen={setIsUrlFormOpen}
                  callback={handleCommsChange}
                  handleSubmit={handleSubmit}
                />
                {type === serviceLogType ? <FileUploadButton file={file} onChange={onChange(colName)} /> : <></>}
                <UrlUploadButton setFormOpen={setIsUrlFormOpen} />
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box style={{ padding: 6, paddingBottom: 10, minHeight: '5vh' }}>
            <FileProgressContext.Provider value={fileProgress}>
              {isEditMode ? (
                <CellSelector
                  cells={cells}
                  callBack={onEdit}
                  col={colName}
                  dropdownTable={dropdownTable}
                  classes={classes}
                  id={id}
                  type={type}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop(colName)}
                  isDragActive={isDragActive}
                />
              ) : (
                <CellViewer
                  type={type}
                  cells={cells}
                  index={colName}
                  callback={onEdit}
                  dropdownTable={dropdownTable}
                  openForm={() => setOpenForm(true)}
                  classes={classes}
                  id={id}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop(colName)}
                  isDragActive={isDragActive}
                />
              )}
            </FileProgressContext.Provider>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <TableRow style={{ display: open ? 'table-row' : 'none', padding: '4px' }}>
        <TableCell colSpan={colSpan} style={{ padding: '4px' }}>
          <Collapse in={open} timeout={250} classes={{ wrapperInner: classes.collapseWrapperInner }}>
            <Box className={classes.colContainer}>{otherColsMap[type].map((col) => renderHiddenCell(col))}</Box>
            <Box className={classes.wideColContainer}>{wideColsMap[type].map((col) => renderHiddenCell(col))}</Box>
            <Box className={classes.fixedWideColContainer}>
              {fixedWideColsMap[type].map((col) => renderHiddenCell(col))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {linkedEventHandler && (
            <NewIssuePopup openForm={openForm} setOpenForm={setOpenForm} title={linkedEventHandler?.newItemModalTitle}>
              <QueryAdder
                dropdownTable={dropdownTable}
                setOpenForm={setOpenForm}
                eventHandler={linkedEventHandler}
                cells={processCellsForAutoComplete(cells, dropdownTable, id)}
                issueId={id}
                linked={true}
              />
            </NewIssuePopup>
      )}
    </>
  );
};

export default TrackerCollapsedRow;
