import { IssueKeys } from '../constants/IssueAndServiceKeys';
import { rowOptions } from '../constants/commonRowValues';
import { mediaToneOptions } from '../constants/constants';
//Some cell values come in the form of array of length 1,
// this function turns the array into a simple object
function getArrayValue(cell) {
  if (Array.isArray(cell)) {
    return cell[0];
  }
  return cell;
}
function isType(cellValue) {
  return cellValue && cellValue.type != null;
}

export default function stylizeCell(cell, classes, cellIndex = '') {
  let cellValue = getArrayValue(cell);

  if (isType(cellValue)) {
    return stylizeTypeCell(cell, classes);
  }

  if (cellIndex === IssueKeys.tone) {
    return stylizeToneCell(cell, classes);
  }

  switch (cellValue?.name ?? cellValue) {
    case rowOptions.COMPLETED:
      return classes.statusCompleted;
    case rowOptions.PARTICIPATION:
      return classes.statusParticipation;
    case rowOptions.PROGRESS:
      return classes.statusProgress;
    case rowOptions.CANCELLED:
      return classes.statusCancelled;
    case rowOptions.YES:
      return classes.drivenYes;
    case rowOptions.RECONCILIATION:
      return classes.reconciliationStyle;
    case rowOptions.TEACHING_AND_LEARNING:
      return classes.teachingAndLearningStyle;
    case rowOptions.EQUITY:
      return classes.equityStyle;
    case rowOptions.SUSTAINABILITY:
      return classes.sustainabilityStyle;
    case rowOptions.HEALTH_INNOVATION:
      return classes.healthThemeStyle;
    case rowOptions.COMPLEXITY1:
      return classes.complexity1;
    case rowOptions.COMPLEXITY2:
      return classes.complexity2;
    case rowOptions.COMPLEXITY3:
      return classes.complexity3;
    case rowOptions.COMPLEXITY4:
      return classes.complexity4;
    case rowOptions.COMPLEXITY5:
      return classes.complexity5;
    default:
      return 'normal';
  }
}

// Will stylize based on type's backgroundColor field if it exists, otherwise leave type cell uncolored
export function stylizeTypeCell(cell, classes) {
  let cellColor = '';
  const colorForType = (typeArr) => {
    if (typeArr.length === 1) {
      cellColor = typeArr[0].backgroundColor;
      if (cellColor === 'Red') return classes.issue;
      else if (cellColor === 'Green') return classes.support;
      return 'normal';
    } else if (
      typeArr.some((type) => {
        cellColor = type.backgroundColor;
        return cellColor === 'Red';
      })
    ) {
      return classes.issue;
    } else if (
      typeArr.some((type) => {
        cellColor = type.backgroundColor;
        return cellColor === 'Green';
      })
    ) {
      return classes.support;
    } else return 'normal';
  };

  return colorForType(cell);
}

export function stylizeColumn(cell, issue, classes) {
  const cellValue = getArrayValue(cell);

  if (cellValue === issue.cells[3]) {
    return classes.typeStyle;
  } else {
    return 'normal';
  }
}

function stylizeToneCell(cell, classes) {
  switch (cell) {
    case mediaToneOptions[0]._id:
      return classes.support;
    case mediaToneOptions[1]._id:
      return classes.statusCancelled;
    case mediaToneOptions[2]._id:
      return classes.issue;
    default:
      return '';
  }
}