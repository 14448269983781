import { API } from '../../shared/constants/constants';
import { cloudfrontRefreshTime } from '../../shared/constants/constants';

export default function refreshCloudfrontCookies() {
  API.put('/user/auth/files', {}, { withCredentials: true })
    .then((response) => {
      // call function every 45 minutes to get new signed cookies
      setTimeout(refreshCloudfrontCookies, cloudfrontRefreshTime);
    })
    .catch((err) => {
      console.error(err);
      window.location.replace(process.env.REACT_APP_COGNITO_URL);
    });
}
