import { IconButton, Tooltip, Zoom } from '@mui/material';
import { Typography, Box, Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import CommentIcon from '@mui/icons-material/Comment';
import { UploadCell } from './CellTypes';
import ErrorBoundary from '../ErrorBoundary';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { Link } from 'react-router-dom';
import { complexityOptions, mediaInteractionType } from '../../shared/constants/constants';
import {
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
  inProgressKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { RouteInformation } from '../../shared/constants/RouteInformation';

export default function CellViewer({
  index,
  cells,
  dropdownTable,
  callback,
  openForm,
  id,
  classes,
  type,
  handleDrag,
  handleDrop,
  isDragActive,
}) {
  let cellData;
  let obj;
  const findExpertName = (id) => {
    obj = dropdownTable['expert']?.find((expert) => expert['_id'] === id);
    return obj ? obj.name : id;
  };
  const dispatch = useFiltersDispatch();
  switch (index) {
    case IssueAndServiceSharedValues.leadExpert:
      if (Array.isArray(cells[index])) {
        cellData = cells[index].map((item) => (item.name ? item.name : findExpertName(item))).join(', ');
      } else {
        cellData = cells[index];
      }
      break;
    case IssueAndServiceSharedValues.teamMembers:
      if (Array.isArray(cells[index])) {
        cellData = cells[index]
          .map((c) => {
            if (c?.name) {
              return c?.name;
            }
            const user = dropdownTable['lead']?.find((lead) => lead['_id'] === c);
            return user ? user.name : null;
          })
          .join(', \n');
      } else {
        obj = dropdownTable['lead']?.find((lead) => lead['_id'] === cells[index]);
        cellData = obj ? obj.name : null;
      }
      break;
    case IssueAndServiceSharedValues.date:
      cellData = new Date(cells[index]).toLocaleDateString().split('T')[0];
      break;
    case IssueKeys.journalist:
      obj = dropdownTable['journalist']?.find((journalist) => journalist['_id'] === cells[index]);
      cellData = obj ? (
        <>
          <div label="name">{obj.name}</div>
          <Box display="inline-flex">
            <Tooltip
              title={
                <Typography align="center">
                  Click to Copy <br /> {obj.phone}
                </Typography>
              }
            >
              <IconButton style={{ padding: 4 }} onClick={() => navigator.clipboard.writeText(obj.phone)} size="large">
                <PhoneIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography align="center">
                  Click to Copy <br /> {obj.cell}
                </Typography>
              }
            >
              <IconButton style={{ padding: 4 }} onClick={() => navigator.clipboard.writeText(obj.cell)} size="large">
                <SmartphoneIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography align="center">
                  Click to Copy <br /> {obj.email}
                </Typography>
              }
            >
              <IconButton style={{ padding: 4 }} onClick={() => navigator.clipboard.writeText(obj.email)} size="large">
                <EmailIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography align="center">Click to Copy: {obj.notes}</Typography>}>
              <IconButton style={{ padding: 4 }} onClick={() => navigator.clipboard.writeText(obj.notes)} size="large">
                <CommentIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ) : null;
      break;
    case IssueKeys.outlet:
    case inProgressKeys.outlet:
      obj = dropdownTable['outlet']?.find((org) => org['_id'] === cells[index]);
      cellData = obj ? obj.name : null;
      break;
    case IssueAndServiceSharedValues.type:
      cellData = cells[index].map((c) => `${c.type}:${c.name}`).join(', \n');
      break;
    case IssueKeys.linkedService:
      cellData = (
        <Box className={classes.stackBox}>
          {id && (
            <Link
              to={RouteInformation.serviceLog.path}
              onClick={() => {
                dispatch({
                  type: 'setFilters',
                  payload: {
                    linkedInteraction: {
                      _id: id,
                    },
                  },
                });
              }}
            >
              View Linked Service
            </Link>
          )}
          <Box className={classes.stackBox}>
            {cells[index].map((c) => (
              <Typography>{c.name}</Typography>
            ))}
          </Box>
          {cells[index].length === 0 && (
            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Create a new Linked Service
            </Button>
          )}
        </Box>
      );
      break;
    case IssueAndServiceSharedValues.campaign:
      //for the time being, we have entries in mongo db that are an array and just a string
      if (Array.isArray(cells[index])) cellData = cells[index].map((c) => `${c.name}`).join(', \n');
      else cellData = cells[index];
      break;
    case IssueAndServiceSharedValues.department:
    case IssueAndServiceSharedValues.unit:
      if (Array.isArray(cells[index])) {
        cellData = cells[index].map((c) => `${c}`).join(', \n');
      } else cellData = cells[index];
      break;
    case ServiceKeys.linkedInteraction:
      cellData =
        cells[index]?.interactions?.length > 0 ? (
          <Box className={classes.stackBox}>
            <Link
              to={RouteInformation.mediaInteraction.path}
              onClick={() => {
                dispatch({
                  type: 'setFilters',
                  payload: {
                    linkedService: [
                      {
                        _id: cells[index]._id,
                        name: cells[index].name,
                        firstLetter: cells[index].name.charAt(0),
                      },
                    ],
                  },
                });
              }}
            >
              View Linked Interactions
            </Link>
            {cells[index].interactions.map((c) => (
              <Typography key={c.outlet}>
                {c.outlet}(Reach {c.reach})
              </Typography>
            ))}

            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Add an interaction
            </Button>
          </Box>
        ) : (
          <Box className={classes.stackBox}>
            No Media Interaction yet
            <Button variant="contained" className={classes.addLinkedButton} onClick={() => openForm()}>
              Add an interaction
            </Button>
          </Box>
        );

      break;
    case IssueKeys.topic:
      cellData = (
        <Typography variant="body2">
          {cells[index]}
          {cells[IssueKeys.file] && cells[IssueKeys.file].length > 0 ? (
            <Tooltip
              title={<Typography variant={'body2'}>{'Issue has attached links'}</Typography>}
              TransitionComponent={Zoom}
              enterDelay={200}
            >
              <AttachFileIcon />
            </Tooltip>
          ) : null}
          {cells[IssueKeys.linkedService] && cells[IssueKeys.linkedService].length > 0 ? (
            <Tooltip
              title={<Typography variant={'body2'}>{'Issue has been linked to a Service'}</Typography>}
              TransitionComponent={Zoom}
              enterDelay={200}
            >
              <LinkIcon />
            </Tooltip>
          ) : null}
        </Typography>
      );
      break;
    case ServiceKeys.service:
      cellData = (
        <Typography variant="body2">
          {cells[index]}
          {cells[ServiceKeys.file] && cells[ServiceKeys.file].length > 0 ? (
            <Tooltip
              title={<Typography variant={'body2'}>{'Service has attached links or documents'}</Typography>}
              TransitionComponent={Zoom}
              enterDelay={200}
            >
              <AttachFileIcon />
            </Tooltip>
          ) : null}
        </Typography>
      );
      break;
    case IssueAndServiceSharedValues.backgroundResponse:
    case IssueAndServiceSharedValues.keyMessaging:
      let data = cells[index];
      const addonKey = index === IssueAndServiceSharedValues.backgroundResponse ? 'response' : 'keyMessaging';

      if (type === mediaInteractionType && cells['Field Addons'] && cells['Field Addons'][addonKey]) {
        data = `${data ? data + '\n' : ''}---- Copied from Linked Service ---- \n${cells['Field Addons'][addonKey]}`;
      }

      cellData = <RichTextEditor defaultValue={''} value={data} readOnly={true} quillStyle={{ resize: 'vertical' }} />;
      break;
    case IssueKeys.file:
      cellData = <UploadCell id={id} cellName={index} files={cells[index]} callback={callback} type={type} />;
      break;
    case ServiceKeys.file:
      cellData = (
        <UploadCell
          id={id}
          cellName={index}
          files={cells[index]}
          callback={callback}
          type={type}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          isDragActive={isDragActive}
        />
      );
      break;
    case ServiceKeys.complexity:
      if (cells[index]) {
        const name = complexityOptions.find((option) => option._id === cells[index])?.name;
        cellData = name ? name : cells[index];
      } else {
        cellData = null;
      }
      break;
    case ServiceKeys.diversity:
      cellData = (
        <Typography variant="body2" style={{ minWidth: '150px' }}>
          {cells[index]}
        </Typography>
      );
      break;
    default:
      cellData = cells[index];
      break;
  }
  return <ErrorBoundary>{cellData}</ErrorBoundary>;
}
