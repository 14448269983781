import { TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AIObject, AIScrapeResult } from './Interfaces';
import { IssueAndServiceSharedValues, IssueKeys } from '../../shared/constants/IssueAndServiceKeys';
import MissingContactButton from './MissingContactButton';
import { cellColors } from '../../shared/constants/commonCellColors';

interface AIResultTableCellProps {
  fieldType: string;
  aiScrapeResult: AIScrapeResult;
  dropdownTable: any;
}

const useStyles = makeStyles(() => ({
  dataCell: {
    padding: '6px',
    textAlign: 'center',
  },
}));

export default function AIResultTableCell({
  fieldType,
  aiScrapeResult,
  dropdownTable,
}: Readonly<AIResultTableCellProps>) {
  const classes = useStyles();

  let textValue: string | AIObject | undefined;
  let contactInTable = true;

  switch (fieldType) {
    case IssueAndServiceSharedValues.unit:
      textValue = aiScrapeResult.Unit?.name ?? aiScrapeResult.Unit;
      contactInTable = !!dropdownTable['units'].find((value: any) => value?.name === textValue);
      break;
    case IssueAndServiceSharedValues.department:
      textValue = aiScrapeResult.Department?.name ?? aiScrapeResult.Department;
      contactInTable = !!dropdownTable['dept'].find((value: any) => value?.name === textValue);
      break;
    case IssueAndServiceSharedValues.date:
      textValue = aiScrapeResult.Date;
      break;
    case IssueAndServiceSharedValues.type:
      textValue = aiScrapeResult.Type;
      break;
    case IssueAndServiceSharedValues.status:
      textValue = aiScrapeResult.Status;
      break;
    case IssueKeys.topic:
      textValue = aiScrapeResult.Topic;
      break;
    case IssueKeys.journalist:
      textValue = aiScrapeResult.Journalist?.name ?? aiScrapeResult.Journalist;
      contactInTable = !!dropdownTable['journalist'].find((value: any) => value?.name === textValue);

      if (aiScrapeResult.Outlet) {
        const outletId = dropdownTable['outlet'].find(
          (value: any) => value?.name === (aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet)
        )?._id;
        if (outletId) {
          const journalistInOutlet = !!dropdownTable['orgAgents'][outletId]?.find(
            (value: any) => value?.name === textValue
          );
          // journalist in table but not with outlet
          contactInTable = contactInTable && journalistInOutlet;
        } else {
          // outlet not in table yet
          contactInTable = false;
        }
      }
      break;
    case IssueKeys.outlet:
      textValue = aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet;
      contactInTable = !!dropdownTable['outlet'].find((value: any) => value?.name === textValue);
      break;
    case IssueKeys.contactMethod:
      textValue = aiScrapeResult.Contact;
      break;
    case IssueAndServiceSharedValues.leadExpert:
      textValue = aiScrapeResult['Lead/Expert']?.name ?? aiScrapeResult['Lead/Expert'];
      contactInTable = !!dropdownTable['expert'].find((value: any) => value?.name === textValue);
      break;
    // TODO add service keys
    default:
      textValue = '';
      break;
  }

  const style =
    !contactInTable && textValue
      ? {
          backgroundColor: cellColors.complexity1.background,
        }
      : null;

  return (
    <TableCell key={fieldType} className={classes.dataCell} sx={style}>
      <Typography variant={'body2'}>{textValue as string}</Typography>
      {!contactInTable && textValue && (
        <MissingContactButton
          fieldType={fieldType}
          dropdownTable={dropdownTable}
          aiScrapeResult={aiScrapeResult}
          textValue={textValue as string}
        />
      )}
    </TableCell>
  );
}
