import axios from 'axios';
import { AutoTrackerKeys, IssueAndServiceSharedValues, IssueKeys, ServiceKeys } from './IssueAndServiceKeys';
import getFirstPartOfPath from '../../utils/getFirstPartOfPath';
import { RouteInformation } from './RouteInformation';
export const transitionTime = 3000;
export const tokenRefreshTime = 45 * 60 * 1000;
export const cloudfrontRefreshTime = 45 * 60 * 1000;
export const CONTACT_MAX_NOTES_LEN = 250;
export const DEFAULT_IS_EMAIL_NOTIFICATIONS_ENABLED = process.env.NODE_ENV === 'production';
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const chartColors = [
  '#329da8',
  '#54a832',
  '#323ea8',
  '#ded41d',
  '#1ddeb4',
  '#de1dd4',
  'rgb(255, 99, 132)',
  '#de1d54',
];

export const paletteColors = ['#002145', '#0055b7', '#00a7e1', '#40b4e5', '#6ec4e8', '#97d4e9'];

export const getTabColor = (pathname) => {
  switch (getFirstPartOfPath(pathname)) {
    case RouteInformation.mediaInteraction.path:
      return paletteColors[0];
    case RouteInformation.serviceLog.path:
      return paletteColors[1];
    case RouteInformation.teamAnalytics.path:
      return paletteColors[2];
    case RouteInformation.customAnalytics.path:
      return paletteColors[3];
    default:
      return paletteColors[0];
  }
};

export const paletteColorsHeaderMain = ['#003777', '#1F6AC0', '#002743'];
export const paletteColorHeaderMiddle = ['#c1d3e8', '#a8cdf7', '#bfdff5'];

const tabColorHeaderMainPicker = [
  {
    pattern: /^\/interactions($|\/.*)/,
    color: paletteColorsHeaderMain[0],
  },
  {
    pattern: /^\/service-log($|\/.*)/,
    color: paletteColorsHeaderMain[1],
  },
  {
    pattern: /.*/,
    color: paletteColorsHeaderMain[2],
  },
];

export const getTabColorHeaderMain = (pathname) => {
  const { color } = tabColorHeaderMainPicker.find(({ pattern }) => pattern.test(pathname));
  return color;
};

const tabColorHeaderMiddlePicker = [
  {
    pattern: /^\/interactions($|\/.*)/,
    color: paletteColorHeaderMiddle[0],
  },
  {
    pattern: /^\/service-log($|\/.*)/,
    color: paletteColorHeaderMiddle[1],
  },
  {
    pattern: /.*/,
    color: paletteColorHeaderMiddle[2],
  },
];

export const getTabColorHeaderMiddle = (pathname) => {
  const { color } = tabColorHeaderMiddlePicker.find(({ pattern }) => pattern.test(pathname));
  return color;
};

export const getBackgroundColor = (view) => {
  switch (view) {
    case serviceLogType:
      return paletteColorsHeaderMain[1];
    case mediaInteractionType:
      return paletteColorsHeaderMain[0];
    default:
      return paletteColorsHeaderMain[2];
  }
};

//This will be changed to the final domain after deployment
const transferProtocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
let host = process.env.NODE_ENV === 'production' ? window.location.host : 'localhost:3000';
if (process.env.REACT_APP_E2E) {
  host = 'server:3000';
}
export const DOMAIN = `${transferProtocol}://${host}`;
export const API_DOMAIN = `${DOMAIN}/api`;
export const API = axios.create({ baseURL: API_DOMAIN });

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    const idToken = localStorage.getItem('id_token');
    config.headers.authorization = `Bearer ${token} ${idToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const visibleIssueCols = [
  IssueKeys.date,
  IssueKeys.topic,
  IssueKeys.type,
  IssueKeys.units,
  IssueKeys.expert,
  IssueKeys.outlet,
  IssueKeys.journalist,
  IssueKeys.campaign,
  IssueKeys.tone,
  IssueKeys.lead,
  IssueKeys.status,
];

export const AutoTrackerInteractionHeaderCols = [
  IssueAndServiceSharedValues.date,
  AutoTrackerKeys.subject,
  IssueKeys.outlet,
  IssueKeys.journalist,
  AutoTrackerKeys.sender,
];

export const AutoTrackerServiceHeaderCols = [
  IssueAndServiceSharedValues.date,
  AutoTrackerKeys.subject,
  IssueAndServiceSharedValues.unit,
  IssueAndServiceSharedValues.leadExpert,
  AutoTrackerKeys.sender,
];

export const RequiredAutoTrackerIssueKeys = [
  IssueKeys.date,
  IssueKeys.topic,
  IssueKeys.type,
  IssueKeys.units,
  IssueKeys.outlet,
  IssueKeys.journalist,
  IssueKeys.expert,
  IssueKeys.status,
];

export const AutoTrackerIssueCols = [...RequiredAutoTrackerIssueKeys, IssueKeys.dept, IssueKeys.contactMethod];

export const RequiredAutoTrackerServiceKeys = [
  ServiceKeys.date,
  ServiceKeys.type,
  ServiceKeys.service,
  ServiceKeys.units,
  ServiceKeys.unitLead,
];

export const AutoTrackerCols = [
  ...RequiredAutoTrackerIssueKeys,
  ServiceKeys.campaign,
  ServiceKeys.dept,
  ServiceKeys.complexity,
];

export const complexityOptions = [
  {
    _id: 1,
    name: '1 - Very simple task/issue',
  },
  {
    _id: 2,
    name: '2 - Ordinary task/issue',
  },
  {
    _id: 3,
    name: '3 - Moderately complicated task/issue',
  },
  {
    _id: 4,
    name: '4 - Complex project/issue',
  },
  {
    _id: 5,
    name: '5 - Extremely complex project/issue',
  },
];

export const diversityOptions = [
  {
    _id: 'Yes',
    name: 'Yes',
  },
  {
    _id: 'N/A',
    name: 'N/A',
  },
  {
    _id: 'No',
    name: 'No',
  },
];

export const genderOptions = [
  {
    _id: 1,
    name: 'Man',
  },
  {
    _id: 2,
    name: 'Woman',
  },
  {
    _id: 3,
    name: 'Non-Binary',
  },
  {
    _id: 4,
    name: 'Other',
  },
];

// These types should be unremovable since it is especially important
// for analytics. Atleast until we implement a way to customize analytics.
export const typesImportantForAnalytics = [
  'Issue Response',
  'Institutional Story',
  'Institutional Story for Issue',
  'Pitch',
  'Media Request',
];

export const typeCategoriesImportantForAnalytics = ['Issue'];

export const visibleServiceLogCols = [
  ServiceKeys.date,
  ServiceKeys.service,
  ServiceKeys.type,
  ServiceKeys.units,
  ServiceKeys.unitLead,
  ServiceKeys.dept,
  ServiceKeys.campaign,
  ServiceKeys.teamMember,
  ServiceKeys.complexity,
  ServiceKeys.status,
];

// TODO use these constants throughout the code
export const mediaInteractionType = 'MediaInteraction';
export const serviceLogType = 'ServiceLog';
export const managerAnalyticsType = 'ManagerAnalytics';
export const clientAnalyticsType = 'ClientAnalytics';

export const socketEvents = Object.freeze({
  AUTHENTICATED: 'authenticated',
  EVENT_ISSUE_ADDED: 'issueAdded',
  EVENT_ISSUE_DELETED: 'issueDeleted',
  EVENT_MONTHLY_ISSUES: 'table_data',
  ROOM_TABLE: 'table',
  EVENT_ALL_ISSUES: 'allIssues',
  EVENT_REQ_MONTHLY_ISSUES: 'monthlyIssues',
  EVENT_NEW_ISSUE: 'newIssue',
  EVENT_ERROR: 'error',
  EVENT_REQ_UPDATE_ISSUE: 'updateIssue',
  EVENT_CONFIRM_DELETE: 'confirmDelete',
  EVENT_REQ_DROPDOWN_TABLE: 'getDropdownTable',
  EVENT_DROPDOWN_TABLE: 'dropdownTable',
  EVENT_REQ_JOURNALISTS: 'getJournalists',
  EVENT_JOURNALISTS: 'journalists',
  EVENT_REQ_EXPERTS: 'getJournalists',
  EVENT_EXPERTS: 'journalists',
  EVENT_REQ_DELETE_ISSUE: 'deleteIssue',
  EVENT_REQ_MONTHLY_SERVICES: 'monthlyServices',
  EVENT_MONTHLY_SERVICES: 'service_data',
  EVENT_SERVICE_DELETED: 'serviceDeleted',
  EVENT_NEW_SERVICE: 'newService',
  EVENT_REQ_UPDATE_SERVICE: 'updateService',
  EVENT_SERVICE_ADDED: 'serviceAdded',
  EVENT_REQ_DELETE_SERVICE: 'deleteService',
  EVENT_ANALYTICS_CHANGE: 'analyticsChange',
  EVENT_NEW_NOTIFICATION_FOR_USER: 'newNotification_',
});

export function getNewUserNotificationSocketEvent(userId) {
  return socketEvents.EVENT_NEW_NOTIFICATION_FOR_USER + userId;
}

export const notificationsType = Object.freeze({
  TEAM_MEMBER_TAGGING: 'TEAM_MEMBER_TAGGING',
});

export const interactionType = Object.freeze({
  ISSUE: 'Issue',
  SUPPORT: 'Support',
  Tactic: 'Tactic',
});

export const userPrivilegeMap = Object.freeze({
  broadsightAdmin: 3,
  admin: 2,
  manager: 1,
  user: 0,
});

export const OrgChangeWarning = `Changing the Outlet for a Journalist will affect Media Interactions and Services.\n
If this Journalist has changed Outlets, please create a new entry.\n`;

export const UnitChangeWarning = `Changing the Unit for an Expert will affect Media Interactions and Services.\n
If this Expert has changed Unit, please create a new entry.\n`;

export const BoldChangeWarning = 'This should only be used if you are correcting a mistake.\n';

export const DeleteOrgWithJournalistsWarning =
  'Cannot delete this Outlet as it is currently attached to journalists. Did you want to edit or merge instead?';

export const BoldDeleteOrgWthJournalistsWarning =
  'If you need to delete, please reassign journalists to other Outlets using journalist "Edit"';

export const ArchiveOrgWithJournalistsWarning =
  'Cannot archive this Outlet as it is currently attached to journalists. Did you want to edit or merge instead?';

export const BoldArchiveOrgWthJournalistsWarning =
  'If you need to archive this Outlet, please archive related journalists using journalist "Edit"';

export const mediaInteractionCategories = [
  IssueKeys.topic,
  IssueKeys.campaign,
  IssueKeys.journalist,
  IssueKeys.expert,
  IssueKeys.keyMessaging,
  IssueKeys.linkedService,
  IssueKeys.response,
  'All',
];

export const servicePageCategories = [
  ServiceKeys.service,
  ServiceKeys.campaign,
  ServiceKeys.keyMessaging,
  ServiceKeys.unitLead,
  'Linked Interactions',
  'All',
];

export const mediaToneOptions = [
  {
    _id: 'Positive',
    name: 'Positive',
  },
  {
    _id: 'Neutral or mixed',
    name: 'Neutral or mixed',
  },
  {
    _id: 'Negative',
    name: 'Negative',
  },
];
