import { useEffect, useState } from 'react';
import { Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Spinner } from 'react-bootstrap';
import ChartContainer from './ChartContainer';
import { chartTypeEnum } from '../constants';
import ErrorComponent from './ErrorComponent';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadIcon from '@mui/icons-material/Download';
import EnlargedChartModal from '../EnlargedChartModal';
import NoDataComponent from './NoDataComponent';
import * as chartAPIRequests from '../../../shared/requests/teamAnalyticsChartRequests';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChartContent from './ChartContent';
import downloadData from '../../../utils/AnalyticsExportUtils';

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: '100vh',
    height: '100%',
    overflow: 'scroll',
    border: 'solid black',
    borderWidth: '1px',
    borderRadius: '1px',
  },
  text: {
    paddingLeft: '5%',
    paddingTop: '1vh',
    color: '#ffffff',
    fontSize: '100%',
  },
  tableHeader: {
    backgroundColor: '#002145',
    background: '#002145',
    height: '5vh',
    textAlign: 'left',
  },
  fullScreenButton: {
    position: 'absolute',
    top: '10%',
    right: '1%',
    zIndex: '1',
  },
  cameraButton: {
    position: 'absolute',
    top: '10%',
    right: '7%',
    zIndex: '1000',
  },
  downloadButton: {
    position: 'absolute',
    top: '10%',
    right: '13%',
    zIndex: '1000',
  },
  dateButton: {
    position: 'absolute',
    top: '10%',
    right: '13%',
    zIndex: '1000',
  },
  gridItem: {
    position: 'absolute',
  },
  content: {
    position: 'relative',
  },
}));

function ChartComponent({
  chartDataIn,
  query,
  dateMI,
  changeYearMI,
  dateQuery,
  dateService,
  changeYearService,
  dateServiceQuery,
}) {
  //component for rending a chart/numbox
  const { title, type, networkRequest, enlargedViewRequest } = chartDataIn;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState('loading');
  const [enlargedViewData, setEnlargedViewData] = useState('loading');

  const supportedDataExports = new Set([
    chartTypeEnum.ChartJSBar,
    chartTypeEnum.ChartJSDoughnut,
    chartTypeEnum.ChartJSStackedBar,
    chartTypeEnum.line,
    chartTypeEnum.PlotlyMonthLine,
    chartTypeEnum.topHits,
    chartTypeEnum.wordCloud,
    chartTypeEnum.numberBox,
  ]);

  useEffect(() => {
    if (networkRequest === chartAPIRequests.getCampaignsMIChart) {
      setChartData('loading');
      networkRequest(dateQuery)
        .then((res) => {
          setChartData(res);
        })
        .catch((e) => setChartData({ error: 1, message: e.message }));
      if (enlargedViewRequest) {
        enlargedViewRequest(dateQuery)
          .then((res) => {
            setEnlargedViewData(res);
          })
          .catch((e) => setEnlargedViewData({ error: 1, message: e.message }));
      }
    }
  }, [dateQuery, networkRequest, enlargedViewRequest]);

  useEffect(() => {
    if (networkRequest === chartAPIRequests.getCampaignsServiceChart) {
      setChartData('loading');
      networkRequest(dateServiceQuery)
        .then((res) => {
          setChartData(res);
        })
        .catch((e) => setChartData({ error: 1, message: e.message }));
      if (enlargedViewRequest) {
        enlargedViewRequest(dateServiceQuery)
          .then((res) => {
            setEnlargedViewData(res);
          })
          .catch((e) => setEnlargedViewData({ error: 1, message: e.message }));
      }
    }
  }, [dateServiceQuery, networkRequest, enlargedViewRequest]);

  useEffect(() => {
    if (
      networkRequest !== chartAPIRequests.getCampaignsMIChart &&
      networkRequest !== chartAPIRequests.getCampaignsServiceChart
    ) {
      setChartData('loading');
      networkRequest(query)
        .then((res) => {
          setChartData(res);
        })
        .catch((e) => setChartData({ error: 1, message: e.message }));
      if (enlargedViewRequest) {
        enlargedViewRequest(query)
          .then((res) => {
            setEnlargedViewData(res);
          })
          .catch((e) => setEnlargedViewData({ error: 1, message: e.message }));
      }
    }
  }, [query, networkRequest, enlargedViewRequest]);

  let openButtonHandler = () => {
    setOpen(true);
  };

  const EnlargedChartComponent = (open, enlargedViewRequest, chartDataIn, enlargedViewData, chartData) => (
    <EnlargedChartModal
      open={open}
      onClose={() => setOpen(false)}
      display={
        enlargedViewRequest ? (
          <ChartContent
            chartDataParam={{
              ...chartDataIn,
              networkRequest: enlargedViewRequest,
              enlargedViewRequest: enlargedViewData,
              chartData: enlargedViewData,
            }}
            query={query}
            open={open}
          ></ChartContent>
        ) : (
          <ChartContent
            chartDataParam={{ ...chartDataIn, chartData: chartData }}
            query={query}
            open={open}
          ></ChartContent>
        )
      }
    ></EnlargedChartModal>
  );

  if (chartData.error === 1) {
    return <ErrorComponent message={chartData.message}></ErrorComponent>;
  } else if (chartData !== 'loading') {
    if (chartData === 'noData') {
      return <NoDataComponent title={title}></NoDataComponent>;
    } else {
      return (
        <>
          {EnlargedChartComponent(open, enlargedViewRequest, chartDataIn, enlargedViewData, chartData)}
          <ChartContainer
            small={type === chartTypeEnum.numberBox || type === chartTypeEnum.topHits}
            large={type === chartTypeEnum.barWide}
          >
            <ChartContent
              className={classes.content}
              chartDataParam={{ ...chartDataIn, chartData: chartData }}
              query={query}
            ></ChartContent>
            <IconButton className={classes.fullScreenButton} onClick={openButtonHandler}>
              <FullscreenIcon />
            </IconButton>
            {supportedDataExports.has(type) ? (
              <IconButton className={classes.downloadButton} onClick={() => downloadData(chartData, type, title)}>
                <DownloadIcon />
              </IconButton>
            ) : null}
            {networkRequest === chartAPIRequests.getCampaignsMIChart ||
            networkRequest === chartAPIRequests.getCampaignsServiceChart ? (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={['year']}
                  label="Year"
                  value={networkRequest === chartAPIRequests.getCampaignsMIChart ? dateMI : dateService}
                  onChange={(newYear) => {
                    networkRequest === chartAPIRequests.getCampaignsMIChart
                      ? changeYearMI(newYear)
                      : changeYearService(newYear);
                  }}
                  className={classes.printButton}
                />
              </LocalizationProvider>
            ) : (
              <></>
            )}
          </ChartContainer>
        </>
      );
    }
  } else {
    return (
      <ChartContainer
        small={type === chartTypeEnum.numberBox || type === chartTypeEnum.topHits}
        large={type === chartTypeEnum.barWide}
      >
        <div>
          <Typography>Waiting for data</Typography>
          <Spinner animation="border" />
        </div>
      </ChartContainer>
    );
  }
}

export default ChartComponent;
