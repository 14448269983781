import { useMutation, useQueryClient, InvalidateQueryFilters } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { configUserMFA, validateUserMFACode, disableUserSMSMFA, reInitUserSMSMFA } from './profilePageRequest';
import { Typography, Modal, Button, TextField, IconButton, InputAdornment, Card, Checkbox } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import MuiPhoneNumber from 'material-ui-phone-number';
import Spinner from 'react-bootstrap/Spinner';
import * as UserActions from '../Authorization/userSlice';

const STEPS = {
  PROMPT: 'PROMPT',
  DISABLE_SMS_MFA: 'DISABLE_SMS_MFA',
  // DISABLE_TOTP_MFA: 'DISABLE_TOTP_MFA',
  // TOKEN_SETUP: 'TOKEN_STEP',
  CONFIG_SMS_MFA: 'CONFIG_SMS_MFA',
  ENTER_CODE: 'ENTER_CODE',
  LOADING: 'LOADING',
};

const useStyles = makeStyles((theme) => ({
  modalCard: {
    padding: '30px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerContainer: {
    paddingBottom: '.5vh',
    borderBottom: '1px gray solid',
  },
  fieldDiv: {
    paddingTop: '1vh',
    paddingBottom: '1vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    color: 'primary',
    width: '100%',
    height: '5vh',
    fontSize: '0.7rem',
  },
  cancelButton: {
    backgroundColor: 'red',
    color: 'white',
    width: '100%',
    height: '5vh',
    fontSize: '0.7rem',
  },
  continueButton: {
    backgroundColor: 'orange',
    color: 'black',
    width: '100%',
    height: '5vh',
    fontSize: '0.6rem',
  },
  resetButton: {
    backgroundColor: 'yellow',
    color: 'black',
    width: '100%',
    height: '5vh',
    fontSize: '0.7rem',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface MFAModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

interface FormData {
  userId: string;
  phone: string;
  password: string;
}

export default function MFAModal({ isModalOpen, setIsModalOpen }: MFAModalProps) {
  const classes = useStyles();
  const [session, setSession] = useState<string | null>(null);
  const [code, setCode] = useState('');
  const [currentStep, setCurrentStep] = useState(STEPS.PROMPT);
  const currentUserInfo = useSelector((state: any) => state.user.userInfo);
  const [formData, setFormData] = useState<FormData>({
    userId: currentUserInfo.id,
    phone: '',
    password: '',
  });

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const queryClient = useQueryClient();

  const handleError = (err: any, errorMessage: string) => {
    dispatch(
      openSnackbar({
        message: errorMessage,
        severity: 'error',
      })
    );
    console.error(err);
    setIsModalOpen(false);
  };

  const handleReInitSMSMFA = async () => {
    setCurrentStep(STEPS.LOADING);
    const resp = await reInitUserSMSMFA(formData);
    return resp;
  };

  const handleConfigMFA = async (form: FormData) => {
    setCurrentStep(STEPS.LOADING);
    const data = await configUserMFA(form);
    return data;
  };

  const configMfaMutation = useMutation({
    mutationFn: () => handleConfigMFA(formData),
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['configMfa', 'ProfilePage'] as InvalidateQueryFilters['queryKey'],
      });

      setSession(data.session);
      setCurrentStep(STEPS.ENTER_CODE);
    },
    onError: (err: any) => handleError(err, 'Unable to complete MFA setup'),
  });

  const validateMfaMutation = useMutation({
    mutationFn: () => {
      if (session === null) {
        throw new Error('session is null');
      }

      return validateUserMFACode(formData.userId, session, code);
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['validateMfa', 'ProfilePage'] as InvalidateQueryFilters['queryKey'],
      });

      localStorage.setItem('id_token', data.id_token);
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);

      const updatedUserInfo = {
        ...currentUserInfo,
        phone: formData.phone,
        mfaSettings: data.mfaSettings,
        mfaEnabled: data.mfaEnabled,
      };

      dispatch(UserActions.setUserInfo(updatedUserInfo));

      dispatch(
        openSnackbar({
          message: 'MFA code validated',
          severity: 'success',
        })
      );
      setIsModalOpen(false);
    },
    onError: (err: any) => handleError(err, 'Unable to validate MFA code'),
  });

  const disableSmsMfaMutation = useMutation({
    mutationFn: () => disableUserSMSMFA(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['disableSmsMfa', 'ProfilePage'] as InvalidateQueryFilters['queryKey'],
      });

      const updatedUserInfo = {
        ...currentUserInfo,
        phone: 'Not Configured',
        mfaSettings: [],
        mfaEnabled: 'Disabled',
      };

      dispatch(UserActions.setUserInfo(updatedUserInfo));

      dispatch(
        openSnackbar({
          message: 'SMS MFA disabled',
          severity: 'warning',
        })
      );
      setIsModalOpen(false);
    },
    onError: (err: any) => handleError(err, 'Unable to disable SMS MFA'),
  });

  const reInitSmsMfaMutation = useMutation({
    mutationFn: handleReInitSMSMFA,
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['reInitSmsMfa', 'ProfilePage'] as InvalidateQueryFilters['queryKey'],
      });

      const updatedUserInfo = {
        ...currentUserInfo,
        ...data,
        phone: 'Not Configured',
        mfaSettings: [],
      };

      dispatch(UserActions.setUserInfo(updatedUserInfo));

      dispatch(
        openSnackbar({
          message: 'SMS MFA reset',
          severity: 'warning',
        })
      );
      setIsModalOpen(false);
    },
    onError: (err: any) => handleError(err, 'Unable to reset SMS MFA'),
  });

  useEffect(() => {
    if (!isModalOpen) {
      setSession(null);
      setCode('');
      setFormData({
        userId: currentUserInfo.id,
        phone: '',
        password: '',
      });
      setCurrentStep(STEPS.PROMPT);
    }
  }, [isModalOpen, currentUserInfo.id]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleFormChange = (field: { name: string; value: string }) => {
    const { name, value } = field;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (value: string) => {
    handleFormChange({ name: 'phone', value });
  };

  const handleSMSMFAChange = (enable: boolean) => {
    if (enable) {
      setCurrentStep(STEPS.CONFIG_SMS_MFA);
    } else {
      setCurrentStep(STEPS.DISABLE_SMS_MFA);
    }
  };

  const handleConfigSMSMFASubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    configMfaMutation.mutate();
  };

  const prevStep = () => {
    switch (currentStep) {
      case STEPS.CONFIG_SMS_MFA:
        setCurrentStep(STEPS.PROMPT);
        break;
      case STEPS.ENTER_CODE:
        setCurrentStep(STEPS.CONFIG_SMS_MFA);
        break;
      case STEPS.DISABLE_SMS_MFA:
        setCurrentStep(STEPS.PROMPT);
        break;
      default:
        break;
    }
  };

  const renderPasswordPrompt = () => {
    return (
      <div className={classes.fieldDiv}>
        <TextField
          label="Password"
          variant="standard"
          type={showPassword ? 'text' : 'password'}
          value={formData.password}
          autoComplete="current-password"
          onChange={(e) => {
            handleFormChange({ name: 'password', value: e.target.value });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.PROMPT:
        return (
          <div>
            <div className={classes.headerContainer}>
              <Typography align="left" variant="h5">
                MFA Settings
              </Typography>
            </div>
            <div>
              <Typography align="left" variant="body1">
                Click the checkbox to enable or disable MFA settings. The next pages will guide you through the MFA
                setup process.
              </Typography>
            </div>
            <div className={classes.fieldDiv}>
              <Checkbox
                checked={
                  currentUserInfo['mfaEnabled'] === 'Enabled' && currentUserInfo['mfaSettings'].includes('SMS_MFA')
                }
                onChange={(e) => {
                  handleSMSMFAChange(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled ' }}
                data-cy="checkbox-smsMfaConfigured-mfaModal"
              />
              SMS MFA Configured
            </div>
            {
              // TODO - TOTP MFA
              /* <Checkbox 
              checked={userInfo['mfaEnabled'] === 'Enabled' && userInfo.mfaSettings.find('SOFTWARE_TOKEN') ? true: false}
              onChange={(e) => {
                handleSMSMFAChange(e.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled '}}
            /> */
            }
          </div>
        );
      case STEPS.DISABLE_SMS_MFA:
        return (
          <div>
            <div className={classes.headerContainer}>
              <Typography align="left" variant="h5">
                Disable SMS MFA
              </Typography>
            </div>
            <div>
              <Typography align="left" variant="body1">
                Are you sure you want to turn off SMS MFA? For added security, consider reinitializing it; next time you
                sign in, you can simply add a new phone number to receive verification codes on that device.
              </Typography>
            </div>
          </div>
        );
      case STEPS.CONFIG_SMS_MFA:
        return (
          <div>
            <Typography align="left" variant="body1">
              Enter the phone number that you'd like to use to receive MFA codes.
            </Typography>
            <form onSubmit={handleConfigSMSMFASubmit}>
              <div className={classes.fieldDiv}>
                <MuiPhoneNumber
                  label="Phone Number"
                  defaultCountry="ca"
                  variant="standard"
                  value={formData.phone}
                  autoComplete="tel"
                  onChange={(val) => handlePhoneNumberChange(val as string)}
                />
                {renderPasswordPrompt()}
              </div>
              <Button type="submit" className={classes.button} variant="contained">
                Request Code
              </Button>
            </form>
          </div>
        );
      case STEPS.ENTER_CODE:
        return (
          <div>
            <Typography align="left" variant="body1">
              Enter the 6 digit code received on the phone number you entered earlier. Click re-send if the timer runs
              out to get another code.
            </Typography>
            <div className={classes.fieldDiv}>
              <TextField label="MFA Code" variant="standard" value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
            Your code will expire in 24 hours.
          </div>
        );
      default:
        return (
          <div>
            <Spinner animation="border" />
          </div>
        );
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="mfa-modal-title"
      aria-describedby="mfa-modal-description"
    >
      <Card className={classes.modalCard}>
        <div>{renderStepContent()}</div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" className={classes.cancelButton} onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          {currentStep !== STEPS.PROMPT && currentStep !== STEPS.LOADING && (
            <Button className={classes.button} variant="contained" onClick={prevStep}>
              Back
            </Button>
          )}
          {currentStep === STEPS.ENTER_CODE && (
            <Button className={classes.button} variant="contained" onClick={() => validateMfaMutation.mutate()}>
              Verify Code
            </Button>
          )}
          {currentStep === STEPS.DISABLE_SMS_MFA && (
            <>
              <Button variant="contained" className={classes.resetButton} onClick={() => reInitSmsMfaMutation.mutate()}>
                Reset MFA
              </Button>
              <Button
                variant="contained"
                className={classes.continueButton}
                onClick={() => disableSmsMfaMutation.mutate()}
              >
                Yes, I'm Sure
              </Button>
            </>
          )}
        </div>
      </Card>
    </Modal>
  );
}
