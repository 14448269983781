import { Collapse, IconButton, TableCell, TableRow, Tooltip, CardContent, Card, Box } from '@mui/material';
import React, { useState } from 'react';
import { EmailQueueEntry } from './Interfaces';
import { EmailEntryEditorCell } from './EmailEntryEditorCell';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import { AIScrapeResultTable } from './AIScrapeResultTable';
import EmailEntryCell from './EmailEntryCell';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

interface EmailEntryRowProps {
  row: EmailQueueEntry;
  dropdownTable: any;
  handleManualAddClick: (email: EmailQueueEntry) => void;
  handleAutomaticAddClick: (email: EmailQueueEntry) => void;
  handleDelete: UseMutationResult<AxiosResponse<any, any>, Error, EmailQueueEntry, unknown>;
  columns: string[];
  eventHandler: any;
  tabColor: string;
}

const useStyles = makeStyles(({ palette }) => ({
  dataCell: {
    padding: '6px',
    textAlign: 'center',
  },
  iconCell: {
    borderRight: 'none',
    borderLeft: 'none',
    '&> button': {
      padding: '4px',
    },
  },
  collapsibleCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  expandedBox: {
    margin: 10,
  },
  hiddenCellCard: {
    flex: 1,
    alignSelf: 'flex-start',
    margin: '10px',
    textAlign: 'center',
    border: `1px solid ${palette.secondary.dark}`,
  },
  cardContent: {
    padding: '0 !important',
    borderRadius: '10%',
  },
  cardName: {
    padding: 8,
    color: palette.primary.contrastText,
    fontWeight: 800,
  },
}));

const EmailEntryRow: React.FC<EmailEntryRowProps> = ({
  row,
  dropdownTable,
  handleManualAddClick,
  handleAutomaticAddClick,
  handleDelete,
  columns,
  eventHandler,
  tabColor,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell className={`${classes.dataCell} ${classes.iconCell}`}>
          <Tooltip title={'Expand row'} placement="top">
            <IconButton aria-label="expand-row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <EmailEntryEditorCell
          className={`${classes.dataCell} ${classes.iconCell}`}
          row={row}
          handleManualAddClick={handleManualAddClick}
          handleAutomaticAddClick={handleAutomaticAddClick}
          handleDelete={handleDelete}
        />
        {columns.map((value) => (
          <EmailEntryCell row={row} column={value} dropdownTable={dropdownTable} key={value} />
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsibleCell} colSpan={7}>
          <Collapse in={open} unmountOnExit>
            <Box className={classes.expandedBox}>
              {row.aiScrapeResult ? (
                <AIScrapeResultTable
                  aiScrapeResult={row.aiScrapeResult}
                  eventHandler={eventHandler}
                  dropdownTable={dropdownTable}
                  tabColor={tabColor}
                />
              ) : (
                <></>
              )}
              <Card className={classes.hiddenCellCard}>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.cardName} style={{ backgroundColor: tabColor }}>
                    Email Body
                  </Box>
                  <Box style={{ padding: 6, paddingBottom: 10, minHeight: '5vh' }}>
                    <RichTextEditor
                      value={row.text || ''}
                      defaultValue={''}
                      readOnly={true}
                      quillStyle={{ resize: 'vertical' }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmailEntryRow;
