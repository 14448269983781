import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from '@mui/icons-material/FilterList';
import CalendarFilterModal from './CalendarFilterModal';
import QueryAdder from '../QueryAdder/QueryAdder';
import NewIssuePopup from '../QueryAdder/NewIssuePopup';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import { API } from '../../shared/constants/constants';
import { needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import { useAtom } from 'jotai';

const getBackgroundColor = (view) => {
  switch (view) {
    case 'service':
      return '#1F6AC0';
    case 'media':
      return '#003777';
    default:
      return '#002743';
  }
};

const useStyles = makeStyles(() => ({
  button: {
    margin: '0 20px',
  },
  newIssueButton: {
    '&.MuiButton-contained': {
      backgroundColor: (props) => getBackgroundColor(props.typeView),
    },
  },
}));

export default function TrackerFilter(props) {
  const { state } = useLocation();
  const { setDataCallback, currMonth, dropdownTable, eventHandler, setFiltersOn, filtersOn, typeView } = props;
  const [needRefetchKeywordSearchedData, setNeedRefetchKeywordSearchedData] = useAtom(
    needRefetchKeywordSearchedDataAtom
  );
  const classes = useStyles({ typeView });
  const [, setMonthlyView] = useState(true);
  const [filterTags, setFilterTags] = useState(state || {});
  const [filterModal, setFilterModal] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  async function globalSearch(state, delayInMilliseconds) {
    if (state && Object.keys(state).length > 0) {
      setDataCallback(null);
      let domain;
      eventHandler.type === 'MediaInteraction' ? (domain = 'issues') : (domain = 'services');
      // There is a race condition that happens when you reload the table (by updating/deleting/adding issues/services) while
      // keyword filters are on. You can add a delay in that case to minimize occurances of that race condition.
      // Note: if delay is 0, we remove setTimeout immediately because setTimeout(()=>{}, 0) still has delay.
      if (delayInMilliseconds > 0) {
        setTimeout(async () => {
          await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
            .then((res) => {
              const filteredIssues = res.data.data || null;

              setDataCallback(filteredIssues);
            })
            .catch((err) => {
              alert(err);
              setDataCallback(null);
            });
        }, delayInMilliseconds);
      } else {
        await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
          .then((res) => {
            const filteredIssues = res.data.data || null;

            setDataCallback(filteredIssues);
          })
          .catch((err) => {
            alert(err);
            setDataCallback(null);
          });
      }
    }
  }

  useEffect(() => {
    const existingTags = Object.keys(filterTags).filter((ft) => filterTags[ft]);
    if (state?.queryString) {
      globalSearch(state, 0);
      setMonthlyView(false);
    } else if (existingTags.length > 0) {
      setMonthlyView(false);
    } else {
      setMonthlyView(true);
    }
    // globalSearch ignored in dep array
    //eslint-disable-next-line
  }, [state, filterTags]);

  useEffect(() => {
    if (state?.queryString && needRefetchKeywordSearchedData) {
      globalSearch(state, 1500);
      setMonthlyView(false);
    }
    setNeedRefetchKeywordSearchedData(false);
    //globalSearch and setNeedRefetchKeywordSearchedData ignored in dep array
    //eslint-disable-next-line
  }, [state, needRefetchKeywordSearchedData]);

  return (
    <Box>
      <Button
        onClick={() => setFilterModal(true)}
        disabled={!dropdownTable || state?.queryString}
        color="primary"
        variant="outlined"
        className={classes.button}
      >
        <FilterListIcon />
        <Typography>Filters</Typography>
      </Button>

      <Button
        variant="contained"
        className={classes.newIssueButton}
        onClick={() => setOpenForm(!openForm)}
        color="primary"
      >
        <AddIcon />
        <Typography>Add service/interaction</Typography>
      </Button>

      <CalendarFilterModal
        open={filterModal}
        setModalOpen={setFilterModal}
        setDataCallback={setDataCallback}
        eventHandler={eventHandler}
        dropdownTable={{
          ...dropdownTable,
          unitHash: dropdownTable?.dropdowns?.unit,
        }}
        filterTags={filterTags}
        setFilterTags={setFilterTags}
        currMonth={currMonth}
        setFiltersOn={setFiltersOn}
        filtersOn={filtersOn}
      />
      <NewIssuePopup openForm={openForm} setOpenForm={setOpenForm} title={eventHandler.newItemModalTitle}>
        <QueryAdder
          dropdownTable={dropdownTable}
          currMonth={currMonth}
          setOpenForm={setOpenForm}
          eventHandler={eventHandler}
        />
      </NewIssuePopup>
    </Box>
  );
}
