import ReactWordcloud from 'react-wordcloud';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const useStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },

  h5: {
    fontSize: '150%',
  },
}));

const options = {
  colors: [
    'rgba(255, 99, 132, 0.7)',
    'rgba(255, 159, 64, 0.7)',
    'rgba(255, 205, 86, 0.7)',
    'rgba(75, 192, 192, 0.7)',
    'rgba(54, 162, 235, 0.7)',
    'rgba(153, 102, 255, 0.7)',
    'rgba(201, 203, 207, 0.7)',
  ],
  enableTooltip: true,
  deterministic: false,
  enableOptimizations: true,
  fontFamily: 'impact',
  padding: 0,
  rotations: 0,
  rotationAngles: [0],
  scale: 'sqrt',
  fontSizes: [30, 70],
  spiral: 'archimedean',
  transitionDuration: 500,
  maxWords: 30,
};

export function WordCloud({ chartData, title }) {
  const classes = useStyles();

  let words = [];

  //data parse to fit wordcloud
  const data_y = chartData[0].y.map((i) => i.replace(/<br>/g, ' '));
  const data_x = chartData[0].x;

  data_y.forEach((text, i) => {
    words.push({ text: text, value: data_x[i] });
  });

  return (
    <>
      <Typography className={classes.h5} variant="h5" align="center" spacing={5}>
        {title}
      </Typography>
      <ReactWordcloud words={words} options={options} />
    </>
  );
}
