import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isAuthenticated: false,
  hasAgreedTermsOfService: true,
  userInfo: {
    username: '',
    id: '',
    email: '',
    privilege: '',
    name: '',
    termsOfServiceAgreed: null,
    tenantId: '',
    phone: '',
    mfaSettings: [],
    mfaEnabled: '',
  },
  addonControls: {
    _id: null,
    tenant: '',
    collaborationActivated: false,
    engagementLogActivated: false,
    trackerAIActivated: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsFetching: (state) => {
      state.isFetching = true;
      state.isAuthenticated = false;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
      state.isFetching = false;
    },
    userLogout: (state) => {
      state.isFetching = false;
      state.isAuthenticated = false;
      state.hasAgreedTermsOfService = true;
      state.userInfo = {
        username: '',
        id: '',
        email: '',
        privilege: '',
        name: '',
        termsOfServiceAgreed: null,
        tenantId: '',
        phone: '',
      };
      state.addonControls = {
        _id: null,
        tenant: '',
        collaborationActivated: false,
        engagementLogActivated: false,
        trackerAIActivated: false,
      };
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserNonPIUserSettings: (state, action) => {
      if (state.userInfo) {
        state.userInfo.nonPIUserSettings = action.payload;
      }
    },
    setUserHasAgreedAgreedTermsOfService: (state, action) => {
      state.hasAgreedTermsOfService = action.payload;
    },
    setUserAddonPageAccessControls: (state, action) => {
      if (action.payload) {
        state.addonControls = action.payload;
      }
    },
  },
});

export const {
  setIsFetching,
  setIsAuthenticated,
  userLogout,
  setUserInfo,
  setUserNonPIUserSettings,
  setUserHasAgreedAgreedTermsOfService,
  setUserAddonPageAccessControls,
} = userSlice.actions;

export default userSlice.reducer;
