import { useState, useEffect } from 'react';
import { Box, Modal, Button, Typography, Container, Tooltip, Chip, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TitleIcon from '@mui/icons-material/Title';
import SchoolIcon from '@mui/icons-material/School';
import FortIcon from '@mui/icons-material/Fort';
import StyleIcon from '@mui/icons-material/Style';
import BuildIcon from '@mui/icons-material/Build';
import DatePicker from 'react-datepicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SelectionInputFilter from './SelectionInputFilter';
import FaceIcon from '@mui/icons-material/Face';
import moment from 'moment';
import { textFiltersHelpText, multipleFilters } from './constants';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { getTabColorHeaderMain } from '../../shared/constants/constants';
import { AnalyticsFiltersEnum, AnalyticsAdvancedFiltersEnum } from './analyticsEnums';
import { AnalyticsFilters, AnalyticsFiltersArrays, AnalyticsTeamMemberFilter } from './analyticsTypes';

const useStyles = makeStyles<DefaultThemeWithTabColor>(({ palette, tabColor }) => {
  return {
    button: {
      margin: '0 20px',
    },
    content: {
      backgroundColor: 'white',
      padding: '30px',
      width: '700px',
      minHeight: '600px',
      maxHeight: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '25px',
      overflow: 'auto',
    },
    filterContainer: {
      margin: '20px 0',
      overflowY: 'scroll',
    },
    title: {
      fontSize: '26px',
      fontWeight: 700,
      textAlign: 'center',
    },
    filterTitle: {
      margin: '10px 0px',
      fontSize: '20px',
      fontWeight: 600,
    },
    filterInput: {
      width: '100%',
    },
    helpText: {
      fontSize: '16px',
      marginLeft: 0,
    },
    filterButton: {
      width: '110px',
      height: '45px',
      fontSize: '16px',
      margin: '0 10px',
      fontWeight: 550,
      '&.MuiButton-contained': {
        background: ({ tabColor }: any) => tabColor,
      },
    },
    advancedButton: {
      width: '150px',
      height: '45px',
      fontSize: '14px',
      margin: '0 10px',
      fontWeight: 550,
      '&.MuiButton-contained': {
        background: ({ tabColor }: any) => tabColor,
      },
    },
    buttonContainer: {
      display: 'block',
      margin: '15px auto',
      textAlign: 'center',
    },
    filterIcon: {
      margin: '0 10px',
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    filterIconNested: {
      padding: '20px 0',
      margin: '2px',
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    groupLabel: {
      background: ({ tabColor }: any) => tabColor,
      color: palette.primary.contrastText,
      fontWeight: 700,
    },
    dateRangeContainer: {
      display: 'inline-flex',
      width: '100%',
      '& > .react-datepicker-wrapper': {
        width: 'auto',
      },
    },
    dateRange: {
      padding: 8,
      margin: 'auto',
      '&:focus-visible': {
        outlineColor: palette.primary.dark,
      },
    },
    dateRangeText: {
      width: '100px',
    },
  };
});

const ALL = 'ALL';
const dateInputFilters = [AnalyticsFiltersEnum.startDate, AnalyticsFiltersEnum.endDate];
const selectInputFilters = [
  AnalyticsFiltersEnum.campaigns,
  AnalyticsFiltersEnum.units,
  AnalyticsFiltersEnum.teamMembers,
];
const advancedInputFilters = [
  AnalyticsAdvancedFiltersEnum.status,
  AnalyticsAdvancedFiltersEnum.type,
  AnalyticsAdvancedFiltersEnum.outlet,
  AnalyticsAdvancedFiltersEnum.dept,
];

const filterIcon = {
  [AnalyticsFiltersEnum.campaigns]: <FortIcon />,
  [AnalyticsFiltersEnum.units]: <SchoolIcon />,
  [AnalyticsFiltersEnum.startDate]: <DateRangeIcon />,
  [AnalyticsFiltersEnum.endDate]: <DateRangeIcon />,
  [AnalyticsFiltersEnum.teamMembers]: <FaceIcon />,

  [AnalyticsAdvancedFiltersEnum.status]: <BuildIcon />,
  [AnalyticsAdvancedFiltersEnum.type]: <StyleIcon />,
  [AnalyticsAdvancedFiltersEnum.outlet]: <SchoolIcon />,
  [AnalyticsAdvancedFiltersEnum.dept]: <TitleIcon />,
};

const dropdownMapping = {
  [AnalyticsFiltersEnum.campaigns]: 'campaigns',
  [AnalyticsFiltersEnum.units]: 'units',
  [AnalyticsFiltersEnum.teamMembers]: 'lead',
};
const advancedDropdownMapping = {
  [AnalyticsAdvancedFiltersEnum.status]: 'status',
  [AnalyticsAdvancedFiltersEnum.type]: 'type',
  [AnalyticsAdvancedFiltersEnum.outlet]: 'outlet',
  [AnalyticsAdvancedFiltersEnum.dept]: 'dept',
};

interface FilterModalProps {
  dropdownTable: any;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setModalOpen: (f: any) => void;
}

export default function FilterModal(props: FilterModalProps) {
  const { setModalOpen, dropdownTable, setQuery } = props;

  const { pathname } = useLocation();

  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });

  // filters has their dates in the form of Date while filterTags has their dates in the form of string
  const [filters, setFilters] = useState<AnalyticsFilters>({});
  const [filterTags, setFilterTags] = useState<AnalyticsFilters>({});

  const [advanced, setAdvanced] = useState(false);
  const [open, setOpen] = useState(false);

  //Here we're setting the setModalOpen function in customAnalytics to update the open state here in SearchModal
  setModalOpen(() => {
    setOpen(true);
  });

  // TODO add return to default view button to utilize this function
  const returnToDefaultView = () => {
    const now = moment().endOf('day').toDate();
    const past = moment().startOf('day').subtract(90, 'days').toDate();

    const formatSingleDigitNum = (dateNumber: number) => {
      return dateNumber.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    };

    // combine state updates so that we dont rerender twice
    unstable_batchedUpdates(() => {
      setFilterTags({
        [AnalyticsFiltersEnum.startDate]: `${
          AnalyticsFiltersEnum.startDate
        } ${past.getUTCFullYear()}-${formatSingleDigitNum(past.getMonth() + 1)}-${formatSingleDigitNum(
          past.getDate()
        )}`,
        [AnalyticsFiltersEnum.endDate]: `${AnalyticsFiltersEnum.endDate} ${now.getUTCFullYear()}-${formatSingleDigitNum(
          now.getMonth() + 1
        )}-${formatSingleDigitNum(now.getDate())}`,
      });

      setFilters({
        ...filters,
        [AnalyticsFiltersEnum.startDate]: past,
        [AnalyticsFiltersEnum.endDate]: now,
      });
    });
  };

  //default view on initial page load
  useEffect(() => {
    returnToDefaultView();
    // eslint-disable-next-line
  }, []);

  const clearFilters = () => {
    setFilterTags({});
    setFilters({});
    setQuery('');
  };

  const onDelete = (tag: AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum, member?: any) => {
    const removeUser = (user: any, memberFilterArray: AnalyticsTeamMemberFilter[]) => {
      if (user === ALL) {
        return undefined;
      }
      const newArray = memberFilterArray.filter((member) => {
        return member !== user;
      });
      return newArray.length > 0 ? newArray : undefined;
    };
    if (multipleFilters.includes(tag)) {
      return () => {
        onSearch({ ...filters, [tag]: removeUser(member, filters[tag] as AnalyticsTeamMemberFilter[]) });
        setFilters({ ...filters, [tag]: removeUser(member, filters[tag] as AnalyticsTeamMemberFilter[]) });
      };
    } else {
      return () => {
        onSearch({ ...filters, [tag]: undefined });
        setFilters({ ...filters, [tag]: undefined });
      };
    }
  };

  const onSearch = async (currFilters: AnalyticsFilters) => {
    setOpen(false);

    const filterTags = { ...currFilters };
    const filterTagsUpdate: any = {};

    dateInputFilters.forEach((df) => {
      if (filterTags[df]) {
        const dateInput = filterTags[df] as Date;
        const offsetMs = dateInput.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(dateInput.getTime() - offsetMs);
        filterTagsUpdate[df] = `${df} ${dateLocal.toISOString().slice(0, 10)}`;
      }
    });

    Object.assign(filterTags, filterTagsUpdate);

    const groupFilterId = (f: AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum) => {
      return `${f.toLowerCase()}=${JSON.stringify(
        (currFilters[f] as AnalyticsFiltersArrays).map((value: any) => value._id)
      )}`;
    };

    const groupFilterURI = (f: AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum) => {
      return `${f.toLowerCase()}=${JSON.stringify(
        (currFilters[f] as AnalyticsFiltersArrays).map((value: any) => encodeURIComponent(value.name))
      )}`;
    };

    const existingFilters: AnalyticsFiltersEnum[] | AnalyticsAdvancedFiltersEnum[] = Object.keys(currFilters).filter(
      (f) => currFilters[f as AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum]
    ) as AnalyticsFiltersEnum[] | AnalyticsAdvancedFiltersEnum[];
    const query = existingFilters
      .map((f) => {
        if (dateInputFilters.includes(f as AnalyticsFiltersEnum)) {
          if (f === AnalyticsFiltersEnum.endDate) {
            const date = new Date(currFilters[f as AnalyticsFiltersEnum] as Date);
            date.setDate(date.getDate() + 1);
            return `${f.toLowerCase().replaceAll(' date', 'Date')}=${date.toISOString()}`;
          }
          return `${f.toLowerCase().replaceAll(' date', 'Date')}=${(
            currFilters[f as AnalyticsFiltersEnum] as Date
          ).toISOString()}`;
        }

        if (multipleFilters.includes(f)) {
          if (f === AnalyticsFiltersEnum.teamMembers) return groupFilterId(f);
          if (f === AnalyticsAdvancedFiltersEnum.outlet) return groupFilterId(f);
          if (f === AnalyticsAdvancedFiltersEnum.type) return groupFilterId(f);
          if (f === AnalyticsFiltersEnum.campaigns) return groupFilterId(f);
          return groupFilterURI(f);
        }

        // Not a valid filter
        return `${f.toLowerCase()}=not_valid_filter`;
      })
      .join('&');
    setFilterTags(filterTags);
    setQuery(query);
  };

  const renderFilterTags = () => {
    const existingTags: AnalyticsFiltersEnum[] | AnalyticsAdvancedFiltersEnum[] = Object.keys(filterTags).filter(
      (ft) => filterTags[ft as AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum]
    ) as AnalyticsFiltersEnum[] | AnalyticsAdvancedFiltersEnum[];
    if (existingTags.length === 0) {
      return;
    }

    return (
      <Box className={classes.buttonContainer}>
        {existingTags.map((tagGroup) => {
          return multipleFilters.includes(tagGroup) ? (
            <Chip
              icon={renderFilterIcon(tagGroup)}
              label={
                <Stack direction={'row'}>
                  {(filterTags[tagGroup] as AnalyticsFiltersArrays).map((tag) => {
                    return (
                      <Chip
                        label={tag.name || tag._id}
                        key={tag.name}
                        variant="outlined"
                        onDelete={onDelete(tagGroup, tag)}
                        className={classes.filterIcon}
                        onClick={() => setOpen(true)}
                      />
                    );
                  })}
                </Stack>
              }
              key={tagGroup}
              variant="outlined"
              onDelete={onDelete(tagGroup, ALL)}
              className={classes.filterIconNested}
            />
          ) : (
            <Chip
              icon={renderFilterIcon(tagGroup)}
              label={filterTags[tagGroup] as string}
              variant="outlined"
              key={tagGroup}
              onDelete={onDelete(tagGroup)}
              className={classes.filterIcon}
              onClick={() => setOpen(true)}
            />
          );
        })}
        <Button
          onClick={() => {
            clearFilters();
          }}
          variant="outlined"
          color="secondary"
        >
          Clear All
        </Button>
      </Box>
    );
  };

  const renderDateRange = () => {
    return (
      <>
        <Typography className={classes.filterTitle}>Date Range</Typography>
        <Box className={classes.dateRangeContainer}>
          <Typography className={`${classes.filterTitle} ${classes.dateRangeText}`} align="left">
            From
          </Typography>
          <DatePicker
            selected={filters[AnalyticsFiltersEnum.startDate] as Date | undefined}
            onChange={(date) => setFilters({ ...filters, [AnalyticsFiltersEnum.startDate]: date as Date })}
            selectsStart
            startDate={filters[AnalyticsFiltersEnum.startDate] as Date | undefined}
            endDate={filters[AnalyticsFiltersEnum.endDate] as Date | undefined}
            className={classes.dateRange}
          />
          <Typography className={`${classes.filterTitle} ${classes.dateRangeText}`} align="center">
            to
          </Typography>
          <DatePicker
            selected={filters[AnalyticsFiltersEnum.endDate] as Date | undefined}
            onChange={(date) => setFilters({ ...filters, [AnalyticsFiltersEnum.endDate]: date as Date })}
            selectsEnd
            startDate={filters[AnalyticsFiltersEnum.startDate] as Date | undefined}
            endDate={filters[AnalyticsFiltersEnum.endDate] as Date | undefined}
            minDate={filters[AnalyticsFiltersEnum.startDate] as Date | undefined}
            className={classes.dateRange}
          />
        </Box>
      </>
    );
  };

  const renderFilterIcon = (filter: AnalyticsFiltersEnum | AnalyticsAdvancedFiltersEnum) => {
    return (
      <Tooltip title={<Typography fontSize={22}>{filter}</Typography>} arrow={true}>
        {filterIcon[filter]}
      </Tooltip>
    );
  };

  const renderAdvancedFields = () => {
    return (
      <SelectionInputFilter
        classes={classes}
        inputFilters={advancedInputFilters}
        dropdownTable={dropdownTable}
        dropdownMapping={advancedDropdownMapping}
        filters={filters}
        setFilters={setFilters}
        textFiltersHelpText={textFiltersHelpText}
        filterIcon={filterIcon}
      />
    );
  };

  const renderModalButtons = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button className={classes.filterButton} variant="outlined" color="primary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          className={classes.filterButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            onSearch(filters);
          }}
        >
          Search
        </Button>
        <Button
          className={classes.advancedButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            setAdvanced(!advanced);
          }}
        >
          {advanced ? 'Basic Fields' : 'Advanced Fields'}
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="filter-modal-title"
        aria-describedby="tracker-filters"
      >
        <Container className={classes.content}>
          <Typography className={classes.title} color="primary">
            Tracker Filters
          </Typography>
          <Box className={classes.filterContainer}>
            {renderDateRange()}
            {advanced && renderAdvancedFields()}
            <SelectionInputFilter
              classes={classes}
              inputFilters={selectInputFilters}
              dropdownTable={dropdownTable}
              dropdownMapping={dropdownMapping}
              filters={filters}
              setFilters={setFilters}
              textFiltersHelpText={textFiltersHelpText}
              filterIcon={filterIcon}
            />
            {renderModalButtons()}
          </Box>
        </Container>
      </Modal>
      {renderFilterTags()}
    </>
  );
}
