import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createCollaborationGroup,
  disbandCollaborationGroup,
  listCollaborationGroups,
  promoteCollaborationGroupOwner,
  quitCollaborationGroup,
  removeCollaborationGroupCollaborator,
  updateCollaborationGroup,
} from '../Requests/CollaborationGroupRequests';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import {
  UseCreateCollaborationGroupMutationProps,
  UseDisbandCollaborationGroupMutationProps,
  UsePromoteCollaborationGroupOwnersMutationProps,
  UseQuitCollaborationGroupMutationProps,
  UseRemoveGroupCollaboratorsMutationProps,
  UseUpdateCollaborationGroupMutationProps,
} from '../Types/hooks';

export const mutationMsgs = {
  createGroup: {
    success: 'Collaboration group added successfully',
    error: 'Failed to add collaboration group',
  },
  updateGroup: {
    success: 'Collaboration group updated successfully',
    error: 'Failed to update collaboration group',
  },
  disbandGroup: {
    success: 'Collaboration group disbanded',
    error: 'Failed to disband collaboration group',
  },
  quitGroup: {
    success: 'Collaboration group quit',
    error: 'Failed to quit collaboration group',
  },
  promoteOwners: {
    success: 'Group Lead(s) promoted successfully',
    error: 'Failed to promote group lead(s)',
  },
  removeCollaborators: {
    success: 'Group Collaborator(s) removed successfully',
    error: 'Failed to remove group collaborator(s)',
  },
};

export const useCreateCollaborationGroupMutation = ({
  onSuccessCallback,
}: UseCreateCollaborationGroupMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: createCollaborationGroup,
    onSuccess: (data) => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.createGroup.success,
          severity: 'success',
        })
      );
      onSuccessCallback(data);
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.createGroup.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useUpdateCollaborationGroupMutation = ({
  onSuccessCallback,
}: UseUpdateCollaborationGroupMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: updateCollaborationGroup,
    onSuccess: (data) => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.updateGroup.success,
          severity: 'success',
        })
      );
      onSuccessCallback(data);
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.updateGroup.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useDisbandCollaborationGroupMutation = ({
  onSuccessCallback,
}: UseDisbandCollaborationGroupMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: disbandCollaborationGroup,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.disbandGroup.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.disbandGroup.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useQuitCollaborationGroupMutation = ({ onSuccessCallback }: UseQuitCollaborationGroupMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: quitCollaborationGroup,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.quitGroup.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.quitGroup.error,
          severity: 'error',
        })
      );
    },
  });
};

export const usePromoteCollaborationGroupOwnersMutation = ({
  onSuccessCallback,
}: UsePromoteCollaborationGroupOwnersMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: promoteCollaborationGroupOwner,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.promoteOwners.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.promoteOwners.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useRemoveGroupCollaboratorsMutation = ({
  onSuccessCallback,
}: UseRemoveGroupCollaboratorsMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: removeCollaborationGroupCollaborator,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.removeCollaborators.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.removeCollaborators.error,
          severity: 'error',
        })
      );
    },
  });
};

// queries
export const useListCollaborationGroupQuery = () => {
  return useQuery({
    queryKey: ['collaboration/groups'],
    queryFn: async () => {
      const groupsListData = await listCollaborationGroups();
      return groupsListData;
    },
    refetchOnWindowFocus: false,
  });
};
