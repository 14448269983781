import { TableCell } from '@mui/material';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DoneIcon from '@mui/icons-material/DoneAllTwoTone';
import RevertIcon from '@mui/icons-material/NotInterestedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopy from '@mui/icons-material/FileCopy';
import ToolTip from '@mui/material/Tooltip';
import { useState } from 'react';
import VerificationModal from '../VerificationModal/VerificationModal';
import { mediaInteractionType } from '../../shared/constants/constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { RouteInformation } from '../../shared/constants/RouteInformation';

export default function TrackerTableEditorCell({
  isEditMode,
  handleEditMode,
  onConfirmEdit,
  onCancelEdit,
  onDelete,
  onDuplicate,
  className,
  showDuplicate = false,
  type,
  rowId,
}) {
  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  return (
    <TableCell className={className}>
      {isEditMode ? (
        <>
          <ToolTip title="Done" placement="left">
            <IconButton onClick={() => onConfirmEdit()} size="large">
              <DoneIcon />
            </IconButton>
          </ToolTip>
          <ToolTip title="Cancel" placement="left">
            <IconButton onClick={() => onCancelEdit()} size="large">
              <RevertIcon />
            </IconButton>
          </ToolTip>
          <ToolTip title="Delete" placement="left">
            <IconButton onClick={() => setOpenVerificationModal(true)} size="large" data-cy="Delete-Button-Editor-Cell">
              <DeleteIcon />
            </IconButton>
          </ToolTip>
          <VerificationModal
            openVerificationModal={openVerificationModal}
            setOpenVerificationModal={setOpenVerificationModal}
            modalTitle={'Confirm Deletion'}
            modalDescription={`Are you sure you want to delete this ${
              type === mediaInteractionType ? 'Media Interaction' : 'Service'
            }?`}
            handleYesClick={() => {
              onDelete();
              setOpenVerificationModal(false);
            }}
          />
        </>
      ) : (
        <>
          <ToolTip title="Edit" placement="left">
            <IconButton onClick={() => handleEditMode(true)} size="large">
              <EditIcon />
            </IconButton>
          </ToolTip>
          {showDuplicate && (
            <ToolTip title="Duplicate" placement="left">
              <IconButton onClick={() => onDuplicate()} size="large">
                <FileCopy />
              </IconButton>
            </ToolTip>
          )}
          <ToolTip title={`Copy URL`} placement="left">
            <IconButton
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}${
                    type === mediaInteractionType
                      ? RouteInformation.mediaInteraction.path
                      : RouteInformation.serviceLog.path
                  }/${rowId}`
                )
              }
              size="large"
            >
              <ContentCopyIcon />
            </IconButton>
          </ToolTip>
        </>
      )}
    </TableCell>
  );
}
