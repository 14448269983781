import { useState } from 'react';
import { Badge, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CollaborationGroupsPage from './CollaborationGroupsPage';
import { useQuery } from '@tanstack/react-query';
import { listCollaborationGroupInvitationInbox } from './Requests/CollaborationGroupInvitationRequests';
import CollaborationGroupInvitationInboxPage from './CollaborationGroupInvitationInboxPage';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import AdminPrivilegeProtected from '../../shared/protectedWrappers/AdminPrivilegeProtectedWrapper';

const useStyles = makeStyles((theme) => ({
  collaborationPageContainer: {
    width: 'calc(100% - 60px)',
    margin: '0 auto',
    marginBottom: 30,
  },
  pageHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  headerActionsContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
}));

const CollaborationPage = () => {
  const classes = useStyles();

  const user = useSelector<StoreState, UserState>((state) => state.user);
  const isAdminPrivilegeProtected = user.userInfo.privilege > PrivilegeLevels.manager.level;

  const [selectedToggleButton, setSelectedToggleButton] = useState<string>('groups');

  const { data } = useQuery({
    queryKey: ['collaboration/group/invitation/inbox'],
    queryFn: listCollaborationGroupInvitationInbox,
    enabled: isAdminPrivilegeProtected,
  });

  const getSelectedButtonVariant = (button: string) => {
    if (selectedToggleButton === button) {
      return 'contained';
    } else {
      return 'outlined';
    }
  };

  const handleButtonClick = (selection: string) => {
    setSelectedToggleButton(selection);
  };

  const renderPageComponent = () => {
    switch (selectedToggleButton) {
      case 'groups':
        return <CollaborationGroupsPage />;
      case 'services':
      case 'interactions':
      case 'contacts':
        return <>Coming soon...</>;
      case 'inbox':
        return (
          <AdminPrivilegeProtected>
            <CollaborationGroupInvitationInboxPage invitations={data ? data : []} />
          </AdminPrivilegeProtected>
        );
      default:
        return null;
    }
  };

  return (
    <Box className={classes.collaborationPageContainer}>
      <Box className={classes.pageHeaderContainer}>
        <Box className={classes.headerActionsContainer}>
          <Button
            data-cy="collaboration-page-tab-groups"
            variant={getSelectedButtonVariant('groups')}
            onClick={() => handleButtonClick('groups')}
          >
            Groups
          </Button>
          <Button
            data-cy="collaboration-page-tab-services"
            variant={getSelectedButtonVariant('services')}
            onClick={() => handleButtonClick('services')}
          >
            Shared Services
          </Button>
          <Button
            data-cy="collaboration-page-tab-interactions"
            variant={getSelectedButtonVariant('interactions')}
            onClick={() => handleButtonClick('interactions')}
          >
            Shared Interactions
          </Button>
          <Button
            data-cy="collaboration-page-tab-contacts"
            variant={getSelectedButtonVariant('contacts')}
            onClick={() => handleButtonClick('contacts')}
          >
            Shared Contacts
          </Button>
        </Box>
        <AdminPrivilegeProtected>
          <Badge
            badgeContent={data ? data.length : 0}
            color="error"
            data-cy="collaboration-page-tab-invitation-inbox-badge"
          >
            <Button
              data-cy="collaboration-page-tab-invitation-inbox"
              variant={getSelectedButtonVariant('inbox')}
              onClick={() => handleButtonClick('inbox')}
            >
              Invitation Inbox
            </Button>
          </Badge>
        </AdminPrivilegeProtected>
      </Box>
      {renderPageComponent()}
    </Box>
  );
};

export default CollaborationPage;
