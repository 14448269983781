import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: '100%',
    marginTop: '4%',
    display: 'flex',
    flexDirection: 'row',
  },
  gridItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  divider: {
    minHeight: '100%',
    width: '10px',
    marginTop: '0px',
    marginLeft: '1vw',
    marginRight: '1vw',
    backgroundColor: 'grey',
  },
  h5: {
    fontSize: '125%',
  },
  h4: {
    fontSize: '150%',
  },
  body2: {
    display: '-webkit-box',
    fontSize: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '5vw',
    width: 'calc(100%)',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function TopHitsBox({ chartData, title }) {
  const classes = useStyles();
  return (
    <Box className={classes.outerContainer}>
      <Typography className={classes.h5} variant="h5" align="left" spacing={5}>
        {title}
      </Typography>
      <Box className={classes.gridContainer}>
        {chartData[0].map((unit, index) => {
          return (
            <React.Fragment key={index}>
              <Box key={index} className={classes.gridItem}>
                <Box className={classes.textContainer}>
                  <Typography className={classes.h4} variant={'h5'}>
                    {' '}
                    {unit.count}
                  </Typography>
                  <Box className={classes.body2} variant={'body2'}>
                    {' '}
                    {unit._id === null || unit._id === '' ? 'No unit' : unit._id}
                  </Box>
                </Box>
              </Box>
              {index !== chartData[0].length - 1 ? <div className={classes.divider} /> : <></>}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}

export default TopHitsBox;
