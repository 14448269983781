import { Card } from 'react-bootstrap';
import CustomAvatar from '../Profile/CustomAvatar';
import { CardActionArea, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNotification, Notification, NotificationType } from '../../shared/requests/notifications';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import moment from 'moment';

const useStyles = makeStyles({
  notificationCard: {
    borderBottomColor: '#F5F5F5',
    border: '1px',
    borderBottomStyle: 'solid',
  },
  notificationCardAction: {
    margin: '0',
    width: '100%',
    height: '100%',
  },
  notificationCardAvatar: {
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
  notificationCardSettings: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: 100,
  },
});

interface NotificationComponentProps {
  sender: any;
  notification: Notification;
  style: React.CSSProperties;
}

export default function NotificationComponent(props: Readonly<NotificationComponentProps>) {
  const { sender, notification, style } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: deleteNotif } = useMutation({
    mutationFn: deleteNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', 'notificationsPage'] });
      dispatch(openSnackbar({ message: `Notification deleted successfully`, severity: 'success' }));
    },
    onError: (error) => {
      console.log(error.message);
      dispatch(openSnackbar({ message: `Notification failed to be deleted`, severity: 'error' }));
    },
  });

  const handleDeleteNotificationButtonClick = () => {
    deleteNotif(notification._id);
  };

  const getNotificationTitle = (notification: { [key: string]: any }) => {
    switch (notification?.notificationType) {
      case NotificationType.TEAM_MEMBER_TAGGING:
        return `You have been tagged!`;
      default:
        return '';
    }
  };

  return (
    <Card className={classes.notificationCard} style={style}>
      <CustomAvatar user={sender} className={classes.notificationCardAvatar} />

      <Tooltip title="Delete">
        <IconButton
          aria-label="settings"
          onClick={handleDeleteNotificationButtonClick}
          className={classes.notificationCardSettings}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <CardActionArea href={notification.redirectTo} className={classes.notificationCardAction}>
        <CardHeader
          title={getNotificationTitle(notification)}
          subheader={moment(new Date(notification.date)).fromNow()}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {notification.content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
